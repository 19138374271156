import { Column, SimpleTable } from "@components/table/SimpleTable";
import { FORMA_PAGAMENTO, MEIO_PAGAMENTO, NFe } from "@models/NFe";
import { TNFeInfNFePagDetPag } from "@models/TNFe";
import { BillingModal } from "../modal/BillingModal";
import { useState } from "react";
import { useNfe } from "@contexts/nfe";
import { NFCe } from "@models/nfce/NFCe";

interface NfeTabProps {
    activeNFe: NFe | NFCe
    editMode: boolean
}

export function BillingTab({ activeNFe, editMode }: NfeTabProps) {

    const [selectedItem, setSelectedItem] = useState<TNFeInfNFePagDetPag>()
    const { editedNfe, updateEditedNfe } = useNfe();

    const columns: Column[] = [
        { header: 'Ind. Forma de Pagamento', accessor: 'indPag', minWidth: 125, formatter: (value) => value && FORMA_PAGAMENTO[value] },
        { header: 'Meio de Pagamento', accessor: 'tpag', minWidth: 210, formatter: (value) => value && MEIO_PAGAMENTO[value] },
        { header: 'Descrição do Meio de Pagamento', accessor: 'xpag', minWidth: 130 }
    ];

    const billing = activeNFe.infNfe?.pag

    const onClickRow = (index, row) => {
        setSelectedItem(billing?.detPag[index]);
    }

    const onChange = (value) => {
        updateEditedNfe({ ...editedNfe, troco: value })
    }

    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Troco</span>
                        <input type="number" style={{ minWidth: 300 }} value={editedNfe?.troco ?? billing?.vtroco ?? ''} disabled={!editMode} onChange={e => onChange(e.target.value)} />
                    </div>
                </div>
            </div>
        </aside>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Formas de Pagamento</span>

            <div className="content">
                <SimpleTable columns={columns} data={billing?.detPag || []} onClickRow={onClickRow} />
            </div>
        </aside>
        {selectedItem && <BillingModal pagDet={selectedItem} handleClose={() => setSelectedItem(undefined)} />}
    </>;
}