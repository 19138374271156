import { FunctionComponent, useState } from "react";
import Modal from "components/modal/modal";
import { Box, Tab, Tabs } from "@mui/material";
import { CustomTabPanel } from "./tabs/CustomTabPanel";
import { NfeTab } from "./tabs/NfeTab";
import { NFE_ENVIRONMENT, NFe, DEST_OPERACAO } from "@models/NFe";
import styled from "styled-components";
import { IssuerTab } from "./tabs/IssuerTab";
import { RecipientTab } from "./tabs/RecipientTab";
import { TotalsTab } from "./tabs/TotalsTab";
import { FreightTab } from "./tabs/FreightTab";
import { AdditionalInfoTab } from "./tabs/AdditionalInfoTab";
import { ItensTab } from "./tabs/ItensTab";
import { BillingTab } from "./tabs/BillingTab";
import { useNfe } from "@contexts/nfe";
import { toast } from "react-toastify";
import { nfeStatus } from "@models/NFeStatus";
import { styled as styledMaterial } from '@mui/material/styles';

const EditModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        transform: translateY(-96px);
        z-index: 1000;

        .modal-body {
            padding: 15px;
            overflow: scroll;
        }
`;

export interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styledMaterial((props: StyledTabsProps) => (
    <Tabs
        {...props}
        scrollButtons="auto"
        variant="scrollable"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: 'var(--color-primary)',
    },
});

export interface StyledTabProps {
    label: string;
}

export const StyledTab = styledMaterial((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    fontFamily: 'TitilliumWeb',
    color: 'rgba(0, 0, 0, 0.85)',
    '&.Mui-selected': {
        color: 'var(--color-primary)',
    },
    '&:hover': {
        color: 'var(--color-primary)',
        opacity: 1,
    },
}));

interface EditNfeProps {
    activeNFe: NFe,
    handleClose: Function,
    handleReissue: Function
}

const EditNfe: FunctionComponent<EditNfeProps> = (props) => {

    const [value, setValue] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [editConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);
    const [cancelEditConfirmDialogOpen, setCancelEditConfirmDialogOpen] = useState(false);
    const { editedNfe, reset, updateEditedNfe } = useNfe();

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const validateNFe = () => {
        if (!editedNfe?.razaoSocial) {
            toast.warn("Razão Social ou nome do destinatário do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.taxId) {
            toast.warn("CNPJ ou CPF ou Id do Estrangeiro do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.endereco) {
            toast.warn("Endereço do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.bairro) {
            toast.warn("Bairro do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.numero) {
            toast.warn("Número do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.codMunicipio) {
            toast.warn("Código do municipio do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.municipio) {
            toast.warn("Municipio do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.uf) {
            toast.warn("UF do destinatário deve ser informado");
            return;
        }

        setEditConfirmDialogOpen(true);

    }

    function startEditMode() {
        updateEditedNfe({
            troco: activeNfe.infNfe?.pag.vtroco,
            infAdFisco: activeNfe.infNfe?.infAdic?.infAdFisco,
            infCpl: activeNfe.infNfe?.infAdic?.infCpl,
            razaoSocial: activeNfe.infNfe?.dest?.xnome,
            taxId: activeNfe.recipientTaxId,
            endereco: activeNfe.infNfe?.dest?.enderDest?.xlgr,
            numero: activeNfe.infNfe?.dest?.enderDest?.nro,
            bairro: activeNfe.infNfe?.dest?.enderDest?.xbairro,
            cep: activeNfe.infNfe?.dest?.enderDest?.cep,
            codMunicipio: activeNfe.infNfe?.dest?.enderDest?.cmun,
            municipio: activeNfe.infNfe?.dest?.enderDest?.xmun,
            fone: activeNfe.infNfe?.dest?.enderDest?.fone,
            codPais: activeNfe.infNfe?.dest?.enderDest?.cpais,
            pais: activeNfe.infNfe?.dest?.enderDest?.xpais,
            uf: activeNfe.infNfe?.dest?.enderDest?.uf,
            suframa: activeNfe.infNfe?.dest?.isuf,
            ie: activeNfe.infNfe?.dest?.ie,
            ieType: activeNfe.infNfe?.dest?.indIEDest,
            im: activeNfe.infNfe?.dest?.im,
            email: activeNfe.infNfe?.dest?.email
        })
        setEditMode(true);

    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClose = () => {
        if (editMode) {
            reset();
            setEditMode(false)
            setCancelEditConfirmDialogOpen(false)
            return;
        }
        setEditMode(false)
        props.handleClose();
    }

    const handleConfirm = () => {
        props.handleReissue(editedNfe);
        setEditConfirmDialogOpen(false);
    }

    const actions = [{
        text: 'Cancelar',
        actionCallback: () => setCancelEditConfirmDialogOpen(true),
        positiveAction: false
    },
    {
        text: 'Salvar',
        actionCallback: () => { validateNFe() },
        positiveAction: true
    }];

    const disableEditMode = () => {
        const status = activeNfe?.status;
        return editMode || (status !== "ERRO_SEFAZ" && status !== "REJEITADO_SEFAZ" && status !== "ERRO_ENVIO")
    }

    const onCloseModal = () => {
        if (editMode) {
            setCancelEditConfirmDialogOpen(true)
            return;
        }
        handleClose();
    }

    const activeNfe = props?.activeNFe;

    if (!activeNfe) {
        return null
    }


    return <EditModalFrame>
        <Modal
            headerTitle={`${editMode ? "Editar" : "Visualizar"} Documento - ${props.activeNFe.infNfe?.ide?.nnf}-${props.activeNFe.infNfe?.ide?.serie}`}
            closeOnClickAway={false}
            handleClose={() => onCloseModal()}
            loading={false}
            headerActions={disableEditMode() ? [] : [
                {
                    icon: (<i title="Editar" className="bi bi-pencil" style={{ fontSize: '1rem' }} />),
                    actionCallback: () => startEditMode()
                }
            ]}
            actionButtons={editMode ? actions : []}
        >
            <div className="edit-frame">
                <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                    <div className="content">
                        <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Chave de acesso</span>
                                <input type="number" disabled={true} value={activeNfe.accessKey} style={{ minWidth: 330 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número</span>
                                <input type="text" disabled={true} value={activeNfe.docNum} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Status</span>
                                <input type="text" disabled={true} value={nfeStatus.filter(st => st.value?.includes(activeNfe.status || ""))[0]?.label ?? ""} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Ambiente</span>
                                <input type="text" disabled={true} value={activeNfe.infNfe?.ide?.tpAmb && NFE_ENVIRONMENT[activeNfe.infNfe?.ide?.tpAmb]} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Versão XML</span>
                                <input type="text" disabled={true} value={activeNfe.infNfe?.versao} />
                            </div>
                        </div>
                    </div>
                </aside>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs value={value} onChange={handleChange}
                        aria-label="scrollable auto tabs example">
                        <StyledTab label="NFe" {...a11yProps(0)} />
                        <StyledTab label="Emitente" {...a11yProps(1)} />
                        <StyledTab label="Destinatário" {...a11yProps(2)} />
                        <StyledTab label="Produtos/Serviços" {...a11yProps(3)} />
                        <StyledTab label="Totais" {...a11yProps(4)} />
                        <StyledTab label="Transporte" {...a11yProps(5)} />
                        <StyledTab label="Cobrança" {...a11yProps(6)} />
                        <StyledTab label="Inf. Adicionais" {...a11yProps(7)} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <NfeTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <IssuerTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <RecipientTab activeNFe={activeNfe} editMode={editMode} isNFCe={false} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <ItensTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <TotalsTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <FreightTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                    <BillingTab activeNFe={activeNfe} editMode={editMode} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={7}>
                    <AdditionalInfoTab activeNFe={activeNfe} editMode={editMode} />
                </CustomTabPanel>

            </div>
        </Modal>

        {!!editConfirmDialogOpen &&
            <Modal
                headerTitle="Confirmar edição do documento"
                closeOnClickAway={false}
                handleClose={() => setEditConfirmDialogOpen(false)}
                loading={false}
                actionButtons={[{
                    text: 'Cancelar',
                    actionCallback: () => setEditConfirmDialogOpen(false),
                    positiveAction: false
                },
                {
                    text: 'Confirmar',
                    actionCallback: () => { handleConfirm() },
                    positiveAction: true
                }]}>
                <div className="confirm-edit-frame">
                    <span>Ao salvar a alteração dos dados da NFe será reenviada para autorização na SEFAZ.</span>
                    <span>Deseja realmente reenviar a solicitação de autorização da NFe?</span>
                </div>
            </Modal>
        }
        {!!cancelEditConfirmDialogOpen &&
            <Modal
                headerTitle="Abandonar a edição do documento"
                closeOnClickAway={false}
                handleClose={() => setCancelEditConfirmDialogOpen(false)}
                loading={false}
                actionButtons={[{
                    text: 'Cancelar',
                    actionCallback: () => setCancelEditConfirmDialogOpen(false),
                    positiveAction: false
                },
                {
                    text: 'Abandonar',
                    actionCallback: () => { handleClose() },
                    positiveAction: true
                }]}>
                <div className="confirm-edit-frame">
                    <span>Deseja realmente abandonar a edição?</span>
                    <span>Lembrando que todos os dados alterados serão perdidos.</span>
                </div>
            </Modal>
        }
    </EditModalFrame>
}

export default EditNfe;