import React, { createContext, useState, useContext } from 'react';


interface NfeContextData {
  editedNfe: EditedNFe | null;
  updateEditedNfe(nfe: EditedNFe | null): void
  reset(): void;
}

export interface EditedNFe {
  troco?: string
  infAdFisco?: string
  infCpl?: string
  razaoSocial?: string
  taxId?: string
  endereco?: string
  numero?: string
  bairro?: string
  cep?: string
  codMunicipio?: string
  municipio?: string
  fone?: string
  codPais?: number
  pais?: string
  uf?: string
  suframa?: string
  ie?: string
  ieType?: number
  im?: string
  email?: string
  taxIdType?: string
}

const NfeContext = createContext<NfeContextData>({} as NfeContextData);

export const NfeProvider: React.FC = ({ children }) => {
  const [editedNfe, setEditedNfe] = useState<EditedNFe | null>(null);

  const updateEditedNfe = (nfe: EditedNFe) => {
    setEditedNfe(nfe)
  }

  const reset = () => {
    setEditedNfe(null)
  }


  return (
    <NfeContext.Provider value={{ editedNfe, updateEditedNfe, reset }}>
      {children}
    </NfeContext.Provider>
  )
}

export const useNfe = (): NfeContextData => {
  const context = useContext(NfeContext);

  return context;
}