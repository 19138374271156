import { NFCe } from "@models/nfce/NFCe";
import { NFe, REGIME_TRIBUTARIO } from "@models/NFe";
import { formatToCEP, formatToPhone, formatToCNPJ } from 'brazilian-values';

interface NfeTabProps {
    activeNFe: NFe | NFCe
}

export function IssuerTab({ activeNFe }: NfeTabProps) {

    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Razão Social</span>
                        <input type="text" style={{ minWidth: 300 }} value={activeNFe?.infNfe?.emit?.xnome} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome Fantasia</span>
                        <input type="text" style={{ minWidth: 300 }} value={activeNFe?.infNfe?.emit?.xfant || undefined} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ</span>
                        <input type="text" value={activeNFe?.infNfe?.emit.cnpj && formatToCNPJ(activeNFe?.infNfe?.emit.cnpj)} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Endereço</span>
                        <input type="text" value={`${activeNFe?.infNfe?.emit?.enderEmit?.xlgr} ${activeNFe?.infNfe?.emit?.enderEmit?.nro ? `, ${activeNFe?.infNfe?.emit?.enderEmit?.nro}` : ''}`} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Bairro / Distrito</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.enderEmit?.xbairro} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CEP</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.enderEmit?.cep && formatToCEP(activeNFe?.infNfe?.emit?.enderEmit?.cep)} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Município</span>
                        <input type="text" value={`${activeNFe?.infNfe?.emit?.enderEmit?.cmun} - ${activeNFe?.infNfe?.emit?.enderEmit?.xmun}`} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Telefone</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.enderEmit?.fone && formatToPhone(activeNFe?.infNfe?.emit?.enderEmit?.fone)} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.enderEmit?.uf} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Pais</span>
                        <input type="text" value={`${activeNFe?.infNfe?.emit?.enderEmit?.cpais} - ${activeNFe?.infNfe?.emit?.enderEmit?.xpais}`} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Estadual</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.ie || undefined} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Estadual do Substituto Tributário</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.iest || undefined} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Municipal</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.im || undefined} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Município da Ocorrência do Fato Gerador do ICMS</span>
                        <input type="text" value={activeNFe?.infNfe?.ide?.cmunFG || undefined} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNAE Fiscal</span>
                        <input type="text" value={activeNFe?.infNfe?.emit?.cnae || undefined} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código de Regime Tributário</span>
                        <input type="text" value={activeNFe?.infNfe?.emit.crt && REGIME_TRIBUTARIO[activeNFe?.infNfe?.emit.crt]} disabled />
                    </div>
                </div>
                
            </div>
        </aside>
    </>;
}