import { Company } from "./Company";
import { NFeHistory } from "./NFeHistory";
import { Recipient } from "./Recipient";
import { TNFeInfNFe } from "./TNFe";

export interface NFe {
    id?: number
    transactionId?: string
    companyId?: number
    issueDate?: string
    docNum?: number
    recipientTaxId?: string
    recipientName?: string
    recipientEmail?: string
    nfAmount?: string
    status?: string
    statusLabel?: string
    series?: string
    erro?: boolean
    authXML?: string
    accessKey?: string
    history?: NFeHistory[]
    reportStatus?: string
    issuerCnpj: string
    company: Company
    recipient?: Recipient
    canEdit?: boolean
    inutialized?: string
    infNfe?: TNFeInfNFe
    digest?: string
}

export const NFE_ENVIRONMENT = {
    1: "Produção",
    2: "Homologação",
    0: ""
}

export const DEST_OPERACAO = {
    1: "1 - Operação Interna",
    2: "2 - Operação Interestadual",
    3: "3 - Exterior"
}

export const CONSUMIDOR_FINAL = {
    1: "1 - Consumidor final",
    0: "0 - Não"
}

export const PRESENCA_COMPRADOR = {
    0: "0 - Não se aplica",
    1: "1 - Operação presencial",
    2: "2 - Não presencial, internet",
    3: "3 - Não presencial, teleatendimento",
    4: "4 - NFC-e entrega em domicílio",
    5: "5 - Operação presencial, fora do estabelecimento",
    9: "9 - Não presencial, outros",
}

export const PROCESSO = {
    0: "0 - emissão de NF-e com aplicativo do contribuinte",
    1: "1 - emissão de NF-e avulsa pelo Fisco",
    2: "2 - emissão de NF-e avulsa, pelo contribuinte com seu certificado digital, através do site do Fisco",
    3: "3 - emissão de NF-e pelo contribuinte com aplicativo fornecido pelo Fisco",
}

export const TIPO_EMISSAO = {
    1: "1 - Normal",
    2: "2 - Contingência FS",
    3: "3 - Regime Especial NFF (NT 2021.002)",
    4: "4 - Contingência DPEC",
    5: "5 - Contingência FSDA",
    6: "6 - Contingência SVC - AN",
    7: "7 - Contingência SVC - RS",
    9: "9 - Contingência off-line NFC-e",
}

export const FINALIDADE = {
    1: "1 - NFe normal",
    2: "2 - NFe complementar",
    3: "3 - NFe de ajuste",
    4: "4 - Devolução/Retorno"
}

export const INTERMEDIADOR = {
    0: "0 - Operação sem intermediador",
    1: "1 - Operação em site ou plataforma de terceiros"
}

export const TIPO_OPERACAO = {
    0: "0 - Entrada",
    1: "1 - Saída"
}

export const REGIME_TRIBUTARIO = {
    1: "1 – Simples Nacional",
    2: "2 – Simples Nacional – excesso de sublimite de receita bruta",
    3: "3 – Regime Normal"
}

export const MOD_FRETE = {
    0: "0 - Contratação do Frete por conta do Remetente (CIF)",
    1: "1 - Contratação do Frete por conta do destinatário/remetente (FOB)",
    2: "2 - Contratação do Frete por conta de terceiros",
    3: "3 - Transporte próprio por conta do remetente",
    4: "4 - Transporte próprio por conta do destinatário",
    9: "9 - Sem Ocorrência de transporte"
}

export const FORMA_PAGAMENTO = {
    0: "0 - Pagamento à Vista",
    1: "1 - Pagamento à Prazo"
}

export const MEIO_PAGAMENTO = {
    "01": "01 - Dinheiro",
    "02": "02 - Cheque",
    "03": "03 - Cartão de Crédito",
    "04": "04 - Cartão de Débito",
    "05": "05 - Crédito Loja",
    "10": "10 - Vale Alimentação",
    "11": "11 - Vale Refeição",
    "12": "12 - Vale Presente",
    "13": "13 - Vale Combustível",
    "14": "14 - Duplicata Mercantil",
    "15": "15 - Boleto Bancário",
    "16": "16 - Depósito Bancário",
    "17": "17 - Pagamento Instantâneo (PIX)",
    "18": "18 - Transferência Bancária, Carteira Digital",
    "19": "19 - Programa de Fidelidade, Cashback, Crédito Virtual",
    "90": "90 - Sem Pagamento",
    "99": "99 - Outros",
}

export const DANFE = {
    "0": "0 - Sem DANFE",
    "1": "1 - DANFE Retrato",
    "2": "2 - DANFE Paisagem",
    "3": "3 - DANFE Simplificado",
    "4": "4 - DANFE NFC-e",
    "5": "5 - DANFE NFC-e em mensagem eletrônica",
}