import { CircularProgress, Popover, Tooltip } from "@mui/material";
import NFCeListingApi from "api/NFCeListingApi";
import { formatToBRL, formatToCPFOrCNPJ } from "brazilian-values";
import Filter from "components/filter/nfceFilter";
import Modal from "components/modal/modal";
import Table, { Column, OrderInfo, TableAction } from "components/table/nfceTable";
import { NFCeFilter } from "model/nfce/NFCeFilter";
import { nfceFilterStatus } from "model/nfce/NFCeFilterStatus";
import { nfceStatus } from "model/nfce/NFCeStatus";
import { NFCe } from "model/nfce/NFCe";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useRef } from "react";
import PageFrame from "components/page-frame/pageFrame";
import { getItem, setItem } from '@services/LocalStorageService';
import PDFViewer from "@components/pdf/pdfViewer";
import HistoryDetails from "@components/historyDetails/historyDetails";
import fileDownload from "js-file-download";
import { useAuth } from '@contexts/auth';
import { PERMISSIONS } from "@utils/permissions";
import { EditedNFe, NfeProvider } from "@contexts/nfe";
import EditNfce from "@components/edit-nfce";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: column;

    .centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .body {
        width: 100%;
        height: 100%;
        max-height: 100%;

        display: flex;
        flex-direction: column;
        position: relative;

        .customize-columns-toggle {
            height: 32px;
            width: fit-content;
            
            padding: 0 8px;

            margin-left: auto;
            
            border: 1px solid var(--grey-light);
            border-radius: 8px;
            
            display: flex; 
            align-items: center; 
            justify-content: center;
            
            user-select: none;
            font-family: 'TitilliumWeb';
            
            cursor: pointer;

            background-color: var(--white);

            :active {
                background-color: var(--grey-lightest);
            }

            :focus-visible {
                outline: none;
            }

            > span {
                text-transform: uppercase;
                font-size: .7rem;
                font-weight: bold;
                letter-spacing: .3px;
            }
        }
    }
    
    .correcao-frame {
        display: flex;
        flex-direction: column;

        > span {
            margin-bottom: 12px;
        }

        > textarea {
            min-width: 550px;
            min-height: 70px;
        }
    }

    .hist-item-frame {
        height: fit-content;
        display: flex;
        align-items: top;

        .data-text {
            white-space: nowrap;
            margin-right: 16px;
        } 
        
        .status-indicator {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            margin-top: 6px;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 6px;
            border: 3px solid var(--color-success);
        }

        .status-link-bar {
            width: 3px;
            min-height: 100%;
            background-color: var(--color-success);
            border-radius: 6px;
            margin-top: 7px;
            margin-left: 12px;
            margin-right: 12px;
        }
    }

    .div-table-cell {
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 15px;
    }

    .line-separator {
        display: table-cell;
        border-left: none;
        border-top: var(--grey-light);
    }
`;

const ModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        transform: translateY(-96px);
        z-index: 1000;
`;

const PAGE_SIZE = 30;

interface PageAndSort {
    sort: OrderInfo;
    page: number;
}

const getFilterStatus = (statusValue: string) => {
    const auxStatus = nfceFilterStatus.filter(s => s.value?.toUpperCase() === statusValue.toUpperCase())[0];
    if (auxStatus) {
        return auxStatus;
    }
}

const getStatusColor = (statusValue: string) => {
    const auxStatus = nfceStatus.filter(s => s.value?.toUpperCase() === statusValue.toUpperCase())[0];
    if (auxStatus) {
        return auxStatus.refColor;
    } else {
        if (statusValue === "ERRO_SEFAZ") {
            return nfceStatus.find(st => st.value === "REJEITADO_SEFAZ")?.refColor;
        }
    }
}

/**
    * Builds the status layout for each individual NFCe item on the list with the apropriate color
    * @param nfceItem represents the NFCe being render on screen
    * @returns a custom JSX element for a NFCe's status
    */
const getStatusLayout = (nfceItem: NFCe) => {
	
    if (nfceItem.reportStatus) {
		let status = getFilterStatus(nfceItem.reportStatus);

		return <div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{
				borderRadius: '50%',
				width: '6px',
				height: '6px',
				marginRight: '6px',
				backgroundColor: status?.refColor
			}}></div>
			<span>{status?.label}</span>
		</div>;
	}

    return <div>{nfceItem.status}</div>;
}

const ListPage = () => {

    /**
         * @param nfceItem NFCe to get the recipient's name from
         * @returns A custom view including a tooltip for recipients names in the list
         */
    const recipientNameToolTip = (nfceItem: NFCe) => <Tooltip title={nfceItem.recipientName || "N/A"} placement="top">
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{nfceItem.recipientName}</div>
    </Tooltip>

    const [columns, setColumns] = useState<Column[]>([
        { header: 'CNPJ Emitente', accessor: 'company.cnpj', isActive: true, minWidth: 125, customView: (value: any) => value.issuerCnpj },
        { header: 'ID Transação', accessor: 'transactionId', isActive: false, minWidth: 210 },
        { header: 'Data de emissão', accessor: 'issueDate', isActive: true, minWidth: 130 },
        { header: 'Nº Documento', accessor: 'docNum', isActive: true, minWidth: 80 },
        { header: 'Série', accessor: 'series', isActive: true, minWidth: 35 },
        { header: 'Valor', accessor: 'nfAmount', isActive: true, minWidth: 85 },
        { header: 'Status', accessor: 'reportStatus', orderAccessor: "status", isActive: true, minWidth: 140, customView: getStatusLayout },
        { header: 'Inutilizada', accessor: 'inutialized', isActive: true, minWidth: 45 },
        { header: 'Chave de Acesso', accessor: 'accessKey', orderAccessor: "accessKey", isActive: false, minWidth: 340 },
        { header: 'Nome Destinatário', accessor: 'recipientName', isActive: true, minWidth: 160, customView: recipientNameToolTip },
        { header: 'Doc. Destinatário', accessor: 'recipientTaxId', isActive: true, minWidth: 125 },
    ]);

    const listingApi = new NFCeListingApi();

    const initialRender = useRef(true);

    const [hasMore, setHasMore] = useState(true);

    const [filters, setFilters] = useState(new NFCeFilter());

    const [data, setData] = useState<NFCe[]>([]);

    const [pageAndSort, setPageAndSort] = useState<PageAndSort>({ sort: { columnOrdered: columns[2], direction: "DESC" }, page: 0 })

    const [totalAmountNFCes, setTotalAmountNFCes] = useState(0);

    const [selectedNFCes, setSelectedNFCes] = useState<NFCe[]>([]);

    const [activeNFCe, setActiveNFCe] = useState<NFCe>();

    const [downloadOptionsOpen, setDownloadOptionsOpen] = useState(false);

    const [visualizarDanfceOpen, setVisualizarDanfceOpen] = useState(false);

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const [nfceHistoryDialogOpen, setNfceHistoryDialogOpen] = useState(false);

    const [viewDialogOpen, setViewDialogOpen] = useState(false);

    const [loadingDownload] = useState<boolean>(false);
    const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
    const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
    const [exportingBils, setExportingBills] = useState<boolean>(false);
    const [downloadingXml, setDownloadingXml] = useState<boolean>(false);
    const [skipNumbersConfirmDialogOpen, setskipNumbersConfirmDialogOpen] = useState(false);

    const { authInfo } = useAuth();
    const [pdf, setPdf] = useState<any>();
    const [pdfFilename, setPdfFilename] = useState<any>();

    const ccRef = useRef<HTMLTextAreaElement>(null);

    const justificationRef = useRef(null);

    const nfceActiveColumn: string = `nfce-columns-active-${authInfo?.username}`

    const validDownloadStatuses: string[] = ['AUTORIZADO', 'CANCELADO', 'NFCE_OFFLINE', 'ERRO_OFFLINE', 'ERRO_ENVIO', 'ERRO_SEFAZ', 'REJEITADO_SEFAZ'];

    const actions = useMemo<TableAction[]>(() => [
        {
            html: <i className="bi bi-download table-action action-positive"></i>,
            callback: (event: Event, nfce: NFCe) => { return handleDownloadClick(event, nfce); },

            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Baixar arquivos',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]
        },
        {
            html: <i className="bi bi-clock-history table-action action-positive" />,
            callback: (event: Event, nfce: NFCe) => handleShowNFCeHistory(event, nfce),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Visualizar histórico',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]
        },
        {
            html: <i className="bi bi-x-lg table-action action-negative" />,
            callback: (event: Event, nfce: NFCe) => handleShowCancelDialog(event, nfce),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Cancelar nota',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFCE.GERENCIAR.CANCELAR]
        },
        {
            html: <i className="bi bi-eye table-action action-positive" />,
            callback: (event: Event, nfe: NFCe) => handleShowNfceViewForm(event, nfe),
            disabled: (actionTooltipText: string, statusLabel: string) => disableAction(actionTooltipText, statusLabel),
            tooltipText: 'Visualizar',
            requiredPermissions: [PERMISSIONS.DOOTAX.NFCE.GERENCIAR.VISUALIZAR]
        },
    ], []);

    useEffect(() => {
        const savedColumnsConfig = getItem(nfceActiveColumn);
        if (savedColumnsConfig) {
            setColumns(columns.map(col => ({ ...col, isActive: savedColumnsConfig.includes(col.accessor) })));
        } else {
            setItem(nfceActiveColumn, columns.map(col => col.accessor).join(','));
        }
    }, []);

    const setFiltersAndResetPageNum = (updatedFilter: NFCeFilter) => {
        setFilters(updatedFilter);
        setPageAndSort({ ...pageAndSort, page: 0 });
    }

    const executeSearch = ({ concatResults }: { concatResults: boolean }) => {
        const { sort: sortInfo, page: pageIndex } = pageAndSort;

        listingApi.fetchFilteredNFCes(filters, pageIndex, PAGE_SIZE, sortInfo.columnOrdered.orderAccessor || sortInfo.columnOrdered.accessor, sortInfo.direction).then(({ content, totalElements, last }) => {
            setTotalAmountNFCes(totalElements);
            setHasMore(!last);

            const newData = content.map(nfce => ({
                ...nfce,
                companyId: (nfce as any).company.id,
                issuerCnpj: formatToCPFOrCNPJ((nfce as any).company.cnpj),
                recipientTaxId: formatToCPFOrCNPJ(nfce.recipientTaxId || ''),
                nfAmount: formatToBRL(nfce.nfAmount || '0'),
                isSelected: false,
                disableSelection: !validDownloadStatuses.includes(nfce.status!),
                inutialized: Boolean(nfce.inutialized) ? "Sim" : "Não"
            }));

            setData(concatResults ? [...data, ...newData] : newData);
        });
    }

    const handleFilter = useCallback(() => executeSearch({ concatResults: false }), [filters, pageAndSort]);

    useEffect(() => {

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            executeSearch({ concatResults: pageAndSort.page !== 0 });
        }
    }, [pageAndSort]);

    useEffect(() => {
        selectedNFCes.length && setData(data.map(item => {
            return {
                ...item,
                isSelected: (selectedNFCes.find(i => (i as any).id === item.id) !== undefined)
            }
        }));
    }, [selectedNFCes]);

    /**
     * Checks if an action should be disabled for a specific NFCe based on it's status
     * @param tooltipText 
     * @param statusLabel 
     * @returns true if the action should be enabled for a specific NFCe
     */
    const disableAction = (tooltipText: string, statusLabel: string) => {
        const statusLower = statusLabel.toLowerCase();
        switch (tooltipText.toLowerCase()) {
            case "cancelar nota":
                return !(statusLower == "autorizado");
            case "baixar arquivos":
                return !(statusLower == "autorizado" || statusLower == "cancelado" || statusLower == "emissão offline" || statusLower == "erro autorização offline");
            default:
                return false;
        }
    }

    const handleExportNFCes = () => {
        setExportingBills(true);

        listingApi.exportNFCes(filters)
            .then(() => toast.success('NFCe\'s exportadas com sucesso!'))
            .finally(() => setExportingBills(false));
    }

    const handlePrint = (pdfBytes: Blob) => {
        var pdfUrl = URL.createObjectURL(pdfBytes);
        var printwWindow = window.open(pdfUrl);
        printwWindow!.print();
    }

    const handleDownloadClick = (event: Event, nfce: NFCe) => {
        setActiveNFCe(nfce);
        setDownloadOptionsOpen(true);
        event.stopPropagation();
    }

    const handleVisualizarDanfceClick = (nfce: NFCe) => {
        setActiveNFCe(nfce);

        listingApi.downloadDanfce(nfce).then(res => {
            if (res) {
                setPdf(res.data)
                setPdfFilename(res.headers.filename);
            } else {
                setPdf(undefined)
                setPdfFilename(undefined);
            }
        });

        setVisualizarDanfceOpen(true);
        setDownloadOptionsOpen(false);
    }

    const handleShowNFCeHistory = (event: Event, nfce: NFCe) => {
        if (!nfce || !nfce.id) {
            toast.error('É necessário selecionar uma NFCe para visualizar seu histórico.');
            return;
        }

        setNfceHistoryDialogOpen(true);
        setLoadingHistory(true);

        listingApi.fetchNFCeHistory(nfce.id)
            .then(res => {
                setLoadingHistory(false);
                setActiveNFCe({ ...nfce, history: res.data });
            }).catch(() => setLoadingHistory(false));

        event.stopPropagation();
    }

    const handleShowNfceViewForm = (event: Event, nfce: NFCe) => {
        if (!nfce || !nfce.id) {
            toast.error('É necessário selecionar uma NFCe para visualizar.');
            return;
        }

        listingApi.fetchNFCeInfo(nfce.transactionId).then(response => {
            setActiveNFCe({ ...nfce, infNfe: response.data.infNFe, infNFeSupl: response.data.infNFeSupl, digest: response.data.digest });
            setViewDialogOpen(true);
            event.stopPropagation();
        }).catch(() => setViewDialogOpen(false));

    }

    const reissueNFe = (editedNFe: EditedNFe) => {
        listingApi.reissueNFCe(activeNFCe?.id, editedNFe).then(() => {
            toast.success("NFCe enviada para a reemissão com sucesso!");
            setViewDialogOpen(false);
            handleFilter();
        })
    }
    

    const handleShowCancelDialog = (event: Event, nfce: NFCe) => {
        setActiveNFCe(nfce);
        nfce && setCancelDialogOpen(true);
        event.stopPropagation();
    }

    const handleCancelNfce = (nfce: NFCe) => {
        const textValue = (justificationRef.current as any).value as string;

        if (!textValue || textValue.length < 15 || textValue.length > 255) {
            toast.warning(`A justificativa deve conter entre 15 e 255 caracteres. Quantidade atual: ${textValue.length}`, { autoClose: 3500 });
            return;
        }

        setLoadingCancel(true);

        listingApi.cancelNFCe(nfce, textValue)
            .then(() => {
                setLoadingCancel(false);
                toast.success("NFCe cancelada com sucesso!");
                setCancelDialogOpen(false);
                handleFilter()
            }).catch(() => {
                setLoadingCancel(false);
                if (ccRef && ccRef.current) {
                    ccRef.current.value = textValue;
                }
            });
    }

    const handleToggleColumn = (column: Column) => {
        const savedColumnsConfig = getItem(nfceActiveColumn)?.split(',');

        if (!savedColumnsConfig || !savedColumnsConfig.length) {
            setItem(nfceActiveColumn, column.accessor);
        } else {
            if (savedColumnsConfig.includes(column.accessor)) {
                savedColumnsConfig.splice(savedColumnsConfig.indexOf(column.accessor), 1);
            } else {
                savedColumnsConfig.unshift(column.accessor);
            }

            setItem(nfceActiveColumn, savedColumnsConfig ? savedColumnsConfig.join(',') : '');
        }
    }

    /**
     * Shows/hides a table column selected by the user
     * @param column selected/unselected column
     */
    const shouldActivateColumn = (column: Column) => {
        setColumns(columns.map(col => {
            if (column.accessor === col.accessor) {
                col.isActive = !column.isActive
                handleToggleColumn(col);
            }
            return { ...col };
        }))
    }

    const [actionsEl, setActionsEl] = useState<HTMLButtonElement | null>(null);

    const handleActionsBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionsEl(event.currentTarget);
    };

    const handleClose = () => {
        setActionsEl(null);
    };

    const open = Boolean(actionsEl);
    const id = open ? 'simple-popover' : undefined;

    const customizeColumnsBtn = <>
        <button
            className="customize-columns-toggle"
            aria-describedby={id}
            onClick={(event) => handleActionsBtnClick(event)}>
            <span>Personalizar colunas</span>
            <i className="bi bi-caret-down-fill" style={{ fontSize: '.75rem', marginLeft: '8px' }} />
        </button>
        <Popover
            id={id}
            open={open}
            anchorEl={actionsEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <div className='customize-columns-menu'>
                <div className="menu-header">
                    <span>Selecione as colunas que deseja exibir na lista:</span>
                </div>
                <div className="menu-items-frame">
                    {
                        columns.slice().sort((a, b) => {
                            if (a.header === b.header) return 0;
                            if (a.header > b.header) return 1;
                            if (a.header < b.header) return -1;
                            return 0;
                        }).map((col, index) => {
                            return <div key={index} style={{ width: 'fit-content', display: 'flex', padding: '10px', alignItems: 'center' }}>
                                <input type="checkbox"
                                    checked={col.isActive}
                                    onChange={() => shouldActivateColumn(col)}
                                />
                                <span>{col.header}</span>
                            </div>
                        })
                    }
                </div>
            </div>
        </Popover>
    </>;



    const handleSortByColumn = (col: Column) => {
        const { sort: sortInfo } = pageAndSort;

        setPageAndSort({
            page: 0,
            sort: {
                columnOrdered: col,
                direction: (col.accessor !== sortInfo.columnOrdered.accessor) || sortInfo.direction === "DESC" ? "ASC" : "DESC"
            }
        });
    }

    const handleSkipNumbersConfirm = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar a inutilização.');
            return;
        }
        setskipNumbersConfirmDialogOpen(true);
    }

    const handleSkipNumbersBatch = () => {
        const ids = selectedNFCes.filter(nfe => !!nfe.id).map(nfe => nfe.id) as number[];

        setskipNumbersConfirmDialogOpen(false);

        const loading = toast.loading('Processando inutilização em massa das notas.');
        listingApi.inutilizeBatch(ids).then(res => {
            toast.success('Inutilização em massa realizada com sucesso.');
            handleFilter();
        }).catch(error => {
            handleFilter();
        }).finally(() => {
            toast.dismiss(loading);
            setSelectedNFCes([]);
        });
    }

    const handleDownloadXml = (nfce: NFCe) => {
        if (!nfce) {
            toast.error('É necessário selecionar uma nota para realizar o download do XML.');
            return;
        }

        setDownloadingXml(true);

        listingApi.downloadXml(nfce).then(res => {
            toast.info(res)

            if (res) {
                if (res.status === 204) {
                    toast.info("Não foi encontrado nenhum xml para esta NFCe.");
                }
            }
        }).finally(() => setDownloadingXml(false));
    }

    const handleDownloadXmlBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar o download do XML.');
            return;
        }

        listingApi.downloadXmlBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi encontrado nenhum XML válido para as notas selecionadas.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    const handleDownloadDanfceBatch = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar o download do DANFe.');
            return;
        }

        listingApi.downloadDanfceBatch(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi possível gerar nenhum DANFe para as notas selecionadas.");
            } else {
                fileDownload(res.data, res.headers.filename)
            }
        });
    }

    const handleDownloadDanfeMerged = (ids: number[]) => {
        if (!ids || !ids.length) {
            toast.warning('É necessário selecionar uma nota para realizar a impressão do DANFe.');
            return;
        }

        listingApi.downloadDanfceMerged(ids).then(res => {
            if (!res || !res.data || res.data.size === 0 || res.status !== 200) {
                toast.info("Não foi possível gerar nenhum DANFe para as notas selecionadas.");
            } else {
                handlePrint(res?.data);
            }
        });
    }

    // ----------------------------
    // RETURN
    // ----------------------------
    return <PageFrame><StyledFrame>
        <section className="body">
            <div style={{ width: '100%', display: "flex", marginBottom: '12px' }}>
                <Filter model={filters} onFiltersChange={(updatedFilter: NFCeFilter) => setFiltersAndResetPageNum(updatedFilter)} reset={() => setFiltersAndResetPageNum(new NFCeFilter())} />
                <button
                    className="customize-columns-toggle"
                    onClick={() => setPageAndSort({ ...pageAndSort, page: 0 })}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                    <i className="bi bi-arrow-clockwise" />
                </button>

                <button disabled={exportingBils} className="icon-button action-button centered" style={{ marginLeft: '20px', width: '188px' }} onClick={() => handleExportNFCes()}>
                    {exportingBils ? (
                        <CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />
                    ) : (
                        <>
                            <i className="bi bi-file-earmark-spreadsheet"></i>
                            <span>Exportar notas</span>
                        </>
                    )}
                </button>
            </div>
            <div style={{ display: 'flex', marginLeft: '6px', alignItems: 'center' }}>
                <span><b style={{ marginLeft: '4px', marginRight: '4px' }}>{totalAmountNFCes}</b> notas encontradas.</span>
                <div style={{ height: '25px', width: '1px', backgroundColor: 'var(--grey-light)', margin: '0 8px', opacity: '.5' }}></div>
                <span><b style={{ marginLeft: '4px', marginRight: '4px' }}>{selectedNFCes.length}</b> notas selecionadas.</span>
                {customizeColumnsBtn}
            </div>
            <Table data={data}
                columns={columns}
                orderInfo={pageAndSort.sort}
                onSortByColumn={(col: Column) => handleSortByColumn(col)}
                actionsConfig={{ actions: actions, minWidth: 130 }}
                disableSelection={false}
                onSelectionChange={(nfces: any) => { setSelectedNFCes(nfces.filter((nf: any) => nf.isSelected)) }}
                handleFetchMore={() => setPageAndSort({ ...pageAndSort, page: (pageAndSort.page + 1) })}
                hasMore={hasMore}
                batchActions={[
                    {
                        label: 'Baixar XML',
                        actionCallback: handleDownloadXmlBatch,
                        isDisabled: selectedNFCes.length === 0
                    },
                    {
                        label: 'Baixar DANFe',
                        actionCallback: handleDownloadDanfceBatch,
                        isDisabled: selectedNFCes.length === 0
                    },
                    {
                        label: 'Imprimir DANFe',
                        actionCallback: handleDownloadDanfeMerged,
                        isDisabled: selectedNFCes.length === 0
                    },
                    {
                        label: 'Inutilizar Rejeitadas',
                        actionCallback: handleSkipNumbersConfirm,
                        isDisabled: selectedNFCes.length === 0
                    }
                ]}
            />
        </section>

        {/* DIALOG - DOWNLOAD ARQUIVOS NFE */}
        {
            !!activeNFCe && !!downloadOptionsOpen && <ModalFrame>
                <Modal
                    headerTitle="Baixar arquivos"
                    closeOnClickAway={true}
                    handleClose={() => setDownloadOptionsOpen(false)}
                    loading={loadingDownload}
                >
                    <span style={{ width: '50%' }}>Selecione a opção desejada:</span>

                    <div style={{ width: '100%', display: 'flex', marginTop: '14px', justifyContent: 'space-around' }}>
                        <button disabled={downloadingXml} className="icon-button centered" style={{ marginRight: '12px' }}
                            onClick={() => handleDownloadXml(activeNFCe)}
                        >
                            {downloadingXml ? (<CircularProgress size={14} style={{ 'color': '#19ea81' }} />) : 'Baixar XML'}
                        </button>
                        <button
                            className="icon-button centered"
                            onClick={() => handleVisualizarDanfceClick(activeNFCe)}
                        >
                            Visualizar DANFE
                        </button>
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - VISUALIZAR DANFE */}

        {visualizarDanfceOpen && (
            <ModalFrame>
                <Modal
                    headerTitle="Visualizar DANFE"
                    closeOnClickAway={true}
                    handleClose={() => {
                        setVisualizarDanfceOpen(false);
                        setPdf(undefined);
                        setPdfFilename(undefined);
                    }}
                    headerActions={[
                        {
                            icon: (<i title="Imprimir" className="bi bi-printer" style={{ fontSize: '1rem' }} />),
                            actionCallback: () => handlePrint(pdf)
                        },
                        {
                            icon: (<i title="Download" className="bi bi-download" style={{ fontSize: '1rem' }} />),
                            actionCallback: () => fileDownload(pdf, pdfFilename)
                        }
                    ]}
                    loading={false}>
                    <PDFViewer pdf={pdf} />
                </Modal>
            </ModalFrame>
        )}

        {/* DIALOG - CANCELAR NFE */}
        {
            !!activeNFCe && !!cancelDialogOpen && <ModalFrame>
                <Modal
                    headerTitle="Cancelar nota"
                    closeOnClickAway={true}
                    handleClose={() => setCancelDialogOpen(false)}
                    loading={loadingCancel}
                    actionButtons={[{
                        text: 'Cancelar',
                        actionCallback: () => setCancelDialogOpen(false),
                        positiveAction: false
                    },
                    {
                        text: 'Confirmar',
                        actionCallback: () => handleCancelNfce(activeNFCe),
                        positiveAction: true
                    }]}
                >
                    <div className="correcao-frame" key={"cancel"}>
                        <span>Digite a justificativa para o cancelamento da nota <b>{activeNFCe && activeNFCe.docNum}</b> :</span>
                        <textarea ref={justificationRef} placeholder="(Texto deve conter entre 15 e 255 caracteres)" />
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - HISTÓRICO NFE */}
        {
            nfceHistoryDialogOpen && <ModalFrame>
                <Modal
                    headerTitle="Histórico da NFCe"
                    closeOnClickAway={false}
                    handleClose={() => setNfceHistoryDialogOpen(false)}
                    loading={loadingHistory}
                >
                    <div style={{ display: 'table-table', border: `1px solid ${'var(--grey-light)'}`, borderRadius: '10px' }}>
                        <div style={{ display: 'table-row', fontWeight: 'bold', textAlign: "center" }}>
                            <div style={{ width: '150px', display: 'table-cell', padding: '10px' }}>Data</div>
                            <div style={{ display: 'table-cell' }}>Status</div>
                            <div style={{ minWidth: '100px', display: 'table-cell' }}>Usuário</div>
                        </div>
                        <div style={{ display: 'table-row' }}>
                            <hr className="line-separator" />
                            <hr className="line-separator" />
                            <hr className="line-separator" />
                        </div>
                        {
                            !!activeNFCe && !!activeNFCe.history && activeNFCe.history.map((histItem, index) => <div key={index} style={{ width: '100%', display: 'table-row', padding: '5px' }} >
                                <div className="div-table-cell" style={{ width: '150px' }}>
                                    {histItem.createdAt}
                                </div>
                                <div className="div-table-cell">
                                    <div className="hist-item-frame">
                                        <div>
                                            <Tooltip title={histItem.status}
                                                placement='top' >
                                                <div className="status-indicator" />
                                            </Tooltip>
                                            {
                                                (!!activeNFCe.history && index < activeNFCe.history.length - 1) ?
                                                    <p className="status-link-bar" ></p>
                                                    : ''
                                            }
                                        </div>
                                        <div style={{ paddingLeft: '15px' }}>
                                            <span style={{
                                                color: `${(index === 0 && histItem.status) ? 'var(--color-success)' : ''}`,
                                                fontSize: '100%',
                                                fontWeight: `${(index === 0 && histItem.status) ? 'bold' : ''}`
                                            }}>{histItem.message}</span>
                                            {histItem.details && !!histItem.details.length && (<HistoryDetails items={histItem.details} />)}
                                        </div>
                                    </div>
                                </div>
                                <div className="div-table-cell" style={{ paddingRight: '10px', textAlign: 'center' }}>
                                    {histItem.username}
                                </div>
                            </div>)
                        }
                    </div>
                </Modal>
            </ModalFrame>
        }

        {/* DIALOG - VISUALIZAR DOCUMENTO */}
        {
            !!activeNFCe && !!viewDialogOpen && <NfeProvider><EditNfce activeNFe={activeNFCe} handleClose={() => setViewDialogOpen(false)} handleReissue={reissueNFe} /></NfeProvider>
        }

        {/* DIALOG - CONFIRM */}
        {!!skipNumbersConfirmDialogOpen && <Modal
            headerTitle="Atenção!"
            closeOnClickAway={false}
            handleClose={() => setskipNumbersConfirmDialogOpen(false)}
            loading={false}
            actionButtons={[{
                text: 'Cancelar',
                actionCallback: () => setskipNumbersConfirmDialogOpen(false),
                positiveAction: false
            },
            {
                text: 'Confirmar',
                actionCallback: () => handleSkipNumbersBatch(),
                positiveAction: true
            }]}>
            <div className="confirm-edit-frame">
                <span>Ao confirmar essa operação, as NFCes selecionadas serão enviadas para inutilização de sua numeração na SEFAZ.</span>
                <br/>
                <span>Deseja realmente inutilizar essas numerações de NFCes?</span>
            </div>
        </Modal>
        }

    </StyledFrame></PageFrame>;
}

export default ListPage;