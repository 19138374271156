import { NFe } from "@models/NFe";
import { TNFeInfNFe } from "@models/TNFe";
import { formatToCEP, formatToCNPJ, formatToCPF, formatToPhone } from "brazilian-values";
import { useNfe } from "@contexts/nfe";
import Picklist, { PickListItem } from "@components/picklist/picklist";
import { NFCe } from "@models/nfce/NFCe";
import { useEffect, useState } from "react";

interface NfeTabProps {
    activeNFe: NFe | NFCe
    editMode: boolean
    isNFCe: boolean
}

const ufList: PickListItem[] = [
    { id: 1, value: "AC", codigo: "AC" },
    { id: 2, value: "AL", codigo: "AL" },
    { id: 3, value: "AM", codigo: "AM" },
    { id: 4, value: "AP", codigo: "AP" },
    { id: 5, value: "BA", codigo: "BA" },
    { id: 6, value: "CE", codigo: "CE" },
    { id: 7, value: "DF", codigo: "DF" },
    { id: 8, value: "ES", codigo: "ES" },
    { id: 9, value: "GO", codigo: "GO" },
    { id: 10, value: "MA", codigo: "MA" },
    { id: 11, value: "MG", codigo: "MG" },
    { id: 12, value: "MS", codigo: "MS" },
    { id: 13, value: "MT", codigo: "MT" },
    { id: 14, value: "PA", codigo: "PA" },
    { id: 15, value: "PB", codigo: "PB" },
    { id: 16, value: "PE", codigo: "PE" },
    { id: 17, value: "PI", codigo: "PI" },
    { id: 18, value: "PR", codigo: "PR" },
    { id: 19, value: "RJ", codigo: "RJ" },
    { id: 20, value: "RN", codigo: "RN" },
    { id: 21, value: "RO", codigo: "RO" },
    { id: 22, value: "RS", codigo: "RS" },
    { id: 23, value: "SE", codigo: "SE" },
    { id: 24, value: "SC", codigo: "SC" },
    { id: 25, value: "RR", codigo: "RR" },
    { id: 26, value: "SP", codigo: "SP" },
    { id: 27, value: "TO", codigo: "TO" },
    { id: 28, value: "EX", codigo: "EX" }
]

const ieTypes: PickListItem[] = [
    {
        id: 1,
        value: "Contribuinte ICMS",
        codigo: "Contribuinte ICMS"
    },
    {
        id: 2,
        value: "Contribuinte Isento",
        codigo: "Contribuinte Isento"
    },
    {
        id: 9,
        value: "Não Contribuinte",
        codigo: "Não Contribuinte"
    }
]

const taxIdTypes: PickListItem[] = [
    {
        id: 1,
        value: "CNPJ",
        codigo: "CNPJ"
    },
    {
        id: 2,
        value: "CPF",
        codigo: "CPF"
    },
    {
        id: 3,
        value: "Id Estrangeiro",
        codigo: "Id Estrangeiro"
    }
]

export function RecipientTab({ activeNFe, editMode, isNFCe }: NfeTabProps) {

    const { editedNfe, updateEditedNfe } = useNfe();
    const [taxIdType, setTaxIdType] = useState(taxIdTypes[0]);

    useEffect(() => {
        var index = 0;
        if (activeNFe.infNfe?.dest?.cpf) {
            index = 1
        } else if (activeNFe.infNfe?.dest?.idEstrangeiro) {
            index = 2
        }
        updateTaxIdType(taxIdTypes[index])
    }, [editMode])

    function formatTaxId(infNfe: TNFeInfNFe | undefined): string {
        if (infNfe?.dest?.cnpj) {
            return formatToCNPJ(infNfe.dest.cnpj);
        } else if (infNfe?.dest?.cpf) {
            return formatToCPF(infNfe.dest.cpf);
        } else if (infNfe?.dest?.idEstrangeiro) {
            return infNfe.dest.idEstrangeiro;
        }

        return "";
    }

    function handleEditTaxId(taxId: string | undefined): string {
        if (!taxId) {
            return ""
        }
        if (taxIdType.id === 1) {
            return formatToCNPJ(taxId);
        } else if (taxIdType.id === 2) {
            return formatToCPF(taxId);
        } else {
            return taxId;
        }
    }

    function fillIeType(ieType: number | undefined): string | undefined {
        if (!ieType) {
            return "";
        }
        if (ieType === 1) {
            return "1 - Contribuinte ICMS"
        } else if (ieType === 2) {
            return "2- Contribuinte Isento"
        }
        return "9 - Não Contribuinte"
    }

    const updateTaxIdType = (type: any): void => {
        setTaxIdType(type)
        updateEditedNfe({ ...editedNfe, taxIdType: type.value })
    }

    const onChange = (field, value) => {
        updateEditedNfe({ ...editedNfe, [field]: value })
    }

    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome/Razão Social</span>
                        <input type="text" style={{ minWidth: 420 }} value={editedNfe?.razaoSocial ?? activeNFe?.infNfe?.dest?.xnome ?? ''} disabled={!editMode} onChange={e => onChange("razaoSocial", e.target.value)} />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    {editMode ? <>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Tipo de Documento</span>
                            <Picklist
                                items={isNFCe ? [taxIdTypes[1]]: taxIdTypes}
                                placeholder="Selecione"
                                selected={taxIdTypes.filter(type => type.id == taxIdType.id)}
                                showEndMessage={false}
                                allowDeselection={false}
                                showSearch={false}
                                itemKey={"taxIdType"}
                                handleApplySelection={(values) => updateTaxIdType(values[0])} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>{taxIdType.codigo}</span>
                            <input type="text" value={handleEditTaxId(editedNfe?.taxId)} disabled={!editMode} onChange={e => onChange("taxId", e.target.value)} />
                        </div>
                    </> : <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>{taxIdType.codigo}</span>
                        <input type="text" value={formatTaxId(activeNFe?.infNfe)} disabled />
                    </div>
                    }
                    {editMode ? <>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Endereço</span>
                            <input type="text" value={editedNfe?.endereco ?? ""} onChange={e => onChange("endereco", e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número</span>
                            <input type="text" value={editedNfe?.numero ?? ""} onChange={e => onChange("numero", e.target.value)} />
                        </div>
                    </>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Endereço</span>
                            <input type="text" value={`${activeNFe?.infNfe?.dest?.enderDest?.xlgr} ${activeNFe?.infNfe?.dest?.enderDest?.nro ? `, ${activeNFe?.infNfe?.dest?.enderDest?.nro}` : ''}`} disabled />
                        </div>
                    }
                </div>

                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Bairro / Distrito</span>
                        <input type="text" value={editedNfe?.bairro ?? activeNFe?.infNfe?.dest?.enderDest?.xbairro ?? ''} onChange={e => onChange("bairro", e.target.value)} disabled={!editMode} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CEP</span>
                        <input type="text" value={formatToCEP(editedNfe?.cep ?? activeNFe?.infNfe?.dest?.enderDest?.cep ?? '')} onChange={e => onChange("cep", e.target.value)} disabled={!editMode} />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    {editMode ? <>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código do Município</span>
                            <input type="text" value={editedNfe?.codMunicipio ?? ""} onChange={e => onChange("codMunicipio", e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome do Município</span>
                            <input type="text" value={editedNfe?.municipio ?? ""} onChange={e => onChange("municipio", e.target.value)} />
                        </div>
                    </>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Município</span>
                            <input type="text" value={`${activeNFe?.infNfe?.dest?.enderDest?.cmun} - ${activeNFe?.infNfe?.dest?.enderDest?.xmun}`} disabled />
                        </div>
                    }

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Telefone</span>
                        <input type="text" value={formatToPhone(editedNfe?.fone ?? activeNFe?.infNfe?.dest?.enderDest?.fone ?? '')} onChange={e => onChange("fone", e.target.value)} disabled={!editMode} />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    {editMode ? <>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código do País</span>
                            <input type="text" value={editedNfe?.codPais ?? ""} onChange={e => onChange("codPais", e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome do País</span>
                            <input type="text" value={editedNfe?.pais ?? ""} onChange={e => onChange("pais", e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                            <Picklist
                                items={ufList}
                                placeholder="Selecione"
                                selected={ufList.filter(uf => uf.codigo == activeNFe?.infNfe?.dest?.enderDest?.uf)}
                                showEndMessage={false}
                                allowDeselection={false}
                                showSearch={false}
                                itemKey={"uf"}
                                handleApplySelection={(values) => onChange("uf", values[0].value)} />
                        </div>
                    </>
                        :
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Pais</span>
                                <input type="text" value={`${activeNFe?.infNfe?.dest?.enderDest?.cpais} - ${activeNFe?.infNfe?.dest?.enderDest?.xpais}`} disabled />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                                <input type="text" value={activeNFe?.infNfe?.dest?.enderDest?.uf} disabled={!editMode} />
                            </div>
                        </>
                    }
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição SUFRAMA</span>
                        <input type="text" value={editedNfe?.suframa ?? activeNFe?.infNfe?.dest?.isuf ?? ""} onChange={e => onChange("suframa", e.target.value)} disabled={!editMode} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginRight: '15px' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Estadual</span>
                            <input type="text" value={editedNfe?.ie ?? activeNFe?.infNfe?.dest?.ie ?? ''} onChange={e => onChange("ie", e.target.value)} disabled={!editMode} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Indicador IE</span>
                            {editMode ?
                                <Picklist
                                    items={ieTypes}
                                    placeholder="Selecione"
                                    selected={ieTypes.filter(ie => ie.id == activeNFe?.infNfe?.dest?.indIEDest)}
                                    showEndMessage={false}
                                    allowDeselection={false}
                                    showSearch={false}
                                    itemKey={"ieType"}
                                    handleApplySelection={(values) => onChange("ieType", values[0].id)} />
                                :
                                <input type="text" value={fillIeType(activeNFe?.infNfe?.dest?.indIEDest)} disabled={!editMode} />
                            }
                        </div>
                    </div>

                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px', maxWidth: 229 }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>IM</span>
                        <input type="number" value={editedNfe?.im ?? activeNFe?.infNfe?.dest?.im ?? ""} onChange={e => onChange("im", e.target.value)} disabled={!editMode} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Email</span>
                        <input type="text" value={editedNfe?.email ?? activeNFe?.infNfe?.dest?.email ?? ""} onChange={e => onChange("email", e.target.value)} disabled={!editMode} />
                    </div>
                </div>
            </div>
        </aside>
    </>;
}