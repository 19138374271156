import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader"
import { nanoid } from "nanoid";

export interface PickListItem {
    id: number
    codigo: string
    value: string
    data?: any
}

interface PickListProps extends React.HTMLAttributes<HTMLDivElement> {
    // mandatory
    items: PickListItem[]
    placeholder: string
    handleApplySelection: Function
    // optional
    selected: PickListItem[]
    enableMultiSelection?: boolean
    allowDeselection?: boolean
    handleSearchTextChange?: Function
    isAsync?: boolean
    handleLoadMore?: Function
    showSearch?: boolean
    onOpen?: Function
    onClose?: Function
    inputRef?: React.MutableRefObject<null>
    hasMore?: boolean
    showEndMessage?: boolean
    itemKey: string
    handleReset?: Function
}

interface SpanProps {
    selected: PickListItem[]
}

const StyledFrame = styled.div`
    min-height: 30px;
    width: ${(props: PickListProps) => props.style?.width ? props.style?.width : '100%'};
    max-width: 100%;

    display: flex;
    flex-direction: column;
    position: relative;

    .disabled {
        pointer-events: none;
        color: #f2f2f2;
    }
    
    .overlay {
        height: 30px;
        width: 100%;

        position: absolute;

        overflow: hidden;

        z-index: 3;
    
        border: 1px solid #dfdfdf;
        border-radius: 8px;
    
        background-color: var(--white);
    
        transition: height .2s ease-in, box-shadow .2s ease-in, background-color .2s ease-in;

        &.is-opened {
            height: ${(props: PickListProps) => {
        return props.enableMultiSelection ?
            ((props.showSearch === undefined || props.showSearch) ? '285px' : '312px') : '256px';
    }};
    
            -webkit-box-shadow: -1px 1px 5px 0px #99999984;
            -moz-box-shadow: -1px 1px 5px 0px #99999984;
            box-shadow: -1px 1px 5px 0px #99999984;
        }

        .header-section {
            min-height: 30px;
            max-width: 100%;
            display: flex;
            padding-left: 10px;
            margin-bottom: 12px;
        
            :hover {
            cursor: pointer;
            }

            span {
                margin: auto 4px;
                width: 100%;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        
            input {
                border: none;
                width: 100%;
        
                :focus-visible {
                    outline: none;
                }
            }
        
            i {
                font-size: .7rem;
                color: var(--color-darkest);
                margin: auto 12px;
                transition: transform .2s ease-in;
            }
        }

        .body-section {
            height: ${(props: PickListProps) => {
        return props.enableMultiSelection ?
            ((props.showSearch === undefined || props.showSearch) ? '280px' : '312px') :
            (props.showSearch === undefined || props.showSearch) ? '206px' : '250px';
    }};
            max-width: 100%;

            display: flex;
            flex-direction: column;
            
            padding-left: 10px;

            .search-frame {
                height: fit-content;
                max-width: 100%;
                display: flex;
                margin: 0 16px 12px 4px;
                padding-left: 4px;
                border: 1px solid var(--grey-light);
                border-radius: 8px;
                opacity: .85;

                input {
                    height: 30px;
                    width: 100%;
                    border: none;
                    border-radius: 8px;

                    :focus-visible {
                        outline: none;
                    }
                }

                i {
                    font-size: .8rem;
                    margin: auto;
                    padding-left: 4px;
                    padding-right: 8px;
                }

                .bi-x {
                    font-size: 1.2rem;
                    color: var(--grey-regular);
                    :hover {
                        cursor: pointer;
                    }
                }
            }

            .list-frame {
                max-height: ${(props: PickListProps) => {
                    return props.enableMultiSelection ? '57%' : '90%';
                }};
                overflow: auto;
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                padding: 4px 4px;

                > span {
                    width: 100%;
                    height: 60px;
                    overflow: hidden;
                    line-height: 60px;
                    margin: auto 0;
                    text-align: center;
                    color: var(--grey-regular);
                }

                .list-item-frame {
                    min-height: 35px;
                    width: 100%;
                    display: flex;
                    justify-items: center;
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;

                    &.is-selected {
                        background-color: var(--grey-lightest);
                    }

                    > input {
                        margin: auto 8px;
                    }

                    > span {
                        margin: auto 8px;
                        color: var(--grey-darkest);
                    }

                    :hover {
                        cursor: pointer;
                        background-color: var(--grey-lightest);
                    }
                }
            }

            > span {
                width: fit-content;
                
                text-align: right;
                
                margin-left: auto;
                margin-right: 0px;
                padding-right: 18px;
                
                color: var(--grey-regular);
                opacity: .7;
                transition: opacity .2s ease-out;

                :hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }

        .footer-section {
            height: 50px;
            width: 100%;
            display: grid;
            place-items: center;
            user-select: none;
            opacity: .8;
            
            :hover {
                opacity: 1;
                cursor: pointer;
            }

            &.enable-apply {
                span {
                    color: var(--color-primary);
                    font-weight: bold;
                }
            }

            span {
                color: var(--grey-light);
            }
        }

        .rotated-180 {
            transform: rotate(180deg);
        }
    }
`;

const StyledValueSpan = styled.span<SpanProps>`
        color: ${props => props.selected.length > 0 ? 'var(--grey-darkest)' : 'var(--grey-regular)'};
        font-weight: ${props => props.selected.length > 0 ? 'bold' : 'normal'};
`;

let searchTimeout: NodeJS.Timeout;

const PickList = (props: PickListProps, ref: Ref<unknown> | undefined) => {

    const [isOpened, setIsOpened] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selected, setSelected] = useState<PickListItem[]>((props.selected && props.selected[0]) ? props.selected : []);
    const [data] = useState(props.items);
    const [filteredData, setFilteredData] = useState(props.items);

    const { enableMultiSelection = false, allowDeselection = false, handleApplySelection: onApplySelection, hasMore = false, isAsync = true, showEndMessage = true, itemKey, onClose, handleReset } = props;

    useEffect(() => {
        if (!enableMultiSelection) {
            setIsOpened(false);
        }
    }, [selected, enableMultiSelection]);

    useEffect(() => {
        props.items && setFilteredData(props.items);
    }, [props.items]);

    useEffect(() => {
        if (!isOpened) {
            onClose && onClose();
            onApplySelection(selected);
            props.handleApplySelection(selected);
        }
    }, [isOpened, onClose, selected]);

    const handleClickOutside = (event: Event) => {
        setIsOpened(false);
        handleApplySelection();
        event.stopPropagation();
    }

    const handleItemClick = (item: PickListItem) => {
        if (enableMultiSelection) {
            setSelected(selected.some(itens => itens.id === item.id) ? selected.filter(i => i.id !== item.id) : [...selected, item]);
        } else {
            setSelected([item]);
        }
    }

    const isSelected = (itemId: number) => {
        return selected && selected.length > 0 && (selected.find(item => item.id === itemId) !== undefined);
    }

    const getLabel = () => {
        if (!selected.length || !selected[0]) return props.placeholder;
        return selected.map(item => item.value).join(', ');
    }

    useImperativeHandle(ref, () => ({
        reset() {
            onClickReset()
        },
        setSelectedOption(value: PickListItem[]) {
            setSelected(value)
        }
    }), [])

    const onChangeSearchText = (newText: string) => {
        if (searchText.trim() !== newText.trim()) { // ignore whitespaces only change
            if (isAsync) {
                clearTimeout(searchTimeout);

                searchTimeout = setTimeout(() => {
                    props.handleSearchTextChange &&
                        props.handleSearchTextChange(newText)?.then((searchResults: PickListItem[]) => {
                            if (searchResults && searchResults.length) {
                                setFilteredData(searchResults);
                            } else {
                                console.log("Não foram encontrados mais resultados para: ", newText);
                            }
                        })
                }, 800);
            } else {
                if (newText === '') {
                    setFilteredData(data);
                } else {
                    setFilteredData(data.filter(item => item.value.toLocaleLowerCase().includes(newText.toLocaleLowerCase())));
                }
            }
        }

        setSearchText(newText);
    }

    /**
     * Check if arrays containing selected items ('selected' and 'props.selected') are equal
     * @returns true if condition is matched
     */
    const areSelectedArraysEqual = () => {

        const auxPropsSelected = (props.selected && props.selected[0]) ? props.selected : [];

        if (selected.length !== auxPropsSelected.length) return false;

        const filteredSelected = selected.map(item => item.id).sort((a, b) => {
            if (a === b)
                return 0;

            if (a > b) {
                return 1;
            } else {
                return -1;
            }
        });

        const filteredPropsSelected = auxPropsSelected.map(item => item.id).sort((a, b) => {
            if (a === b)
                return 0;

            if (a > b) {
                return 1;
            } else {
                return -1;
            }
        });

        for (let i = 0; i < filteredSelected.length; i++) {
            if (filteredSelected[i] !== filteredPropsSelected[i]) return false;
        }

        return true;
    }

    const handleApplySelection = () => {
        setIsOpened(false);
        props.handleApplySelection(selected);
    }

    const onClickReset = () => {
        setSelected([])
        handleReset && handleReset();
    }

    const infiniteScrollFrameId = nanoid();

    return <ClickAwayListener onClickAway={(event) => { isOpened && handleClickOutside(event) }}>

        <StyledFrame {...props} id="picklist" ref={props.inputRef}>

            <div className={'overlay '.concat(isOpened ? 'is-opened' : '')} >

                <section className="header-section" onClick={() => { setIsOpened(!isOpened); props.onOpen && props.onOpen(); }}>
                    <StyledValueSpan selected={selected}>{getLabel()}</StyledValueSpan>
                    <i className={`bi bi-caret-down-fill ${isOpened && 'rotated-180'}`}></i>
                </section>

                <section className={`body-section ${isOpened && 'is-opened'}`}>
                    {/* Search */}
                    {
                        ((props.showSearch === undefined) || props.showSearch) &&
                        <div className="search-frame">
                            <i className="bi bi-search"></i>
                            <input placeholder="Pesquisar" value={searchText} onChange={event => onChangeSearchText(event.target.value)}></input>
                            {(searchText !== '') ? <i className="bi bi-x" onClick={() => { onChangeSearchText('') }}></i> : ''}
                        </div>
                    }
                    {!!allowDeselection && !!selected.length && <span className={!!selected.length ? '' : 'disabled'} onClick={onClickReset}> {enableMultiSelection ? 'Desmarcar todos' : 'Desfazer'}</span>}
                    {/* List */}
                    <div className="list-frame" id={infiniteScrollFrameId}>
                        {
                            (filteredData.length > 0) && <InfiniteScroll
                                dataLength={filteredData.length}
                                next={() => props.handleLoadMore && props.handleLoadMore()}
                                hasMore={hasMore}
                                scrollableTarget={infiniteScrollFrameId}
                                scrollThreshold="50px"
                                loader={
                                    <div className="loading">
                                        <ContentLoader
                                            speed={2}
                                            width="100%"
                                            height={30}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#8cf48a"
                                        >
                                            <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
                                            <rect x="99" y="0" rx="3" ry="3" width="24" height="11" />
                                            <rect x="555" y="0" rx="3" ry="3" width="53" height="11" />
                                            <rect x="160" y="0" rx="3" ry="3" width="140" height="11" />
                                            <rect x="340" y="0" rx="3" ry="3" width="173" height="11" />
                                        </ContentLoader>
                                    </div>
                                }
                                endMessage={showEndMessage &&
                                    <div className="end-message" style={{ pointerEvents: 'none' }}>
                                        Fim dos resultados
                                    </div>
                                }
                            >
                                {
                                    filteredData.map(item => {
                                        return <div key={itemKey + "-" + item.id}
                                            className={`list-item-frame ${isSelected(item.id) && 'is-selected'}`}
                                            onClick={() => handleItemClick(item)}>
                                            {enableMultiSelection && <input type="checkbox" checked={isSelected(item.id)} readOnly={true} />}
                                            <span>{item.value}</span>
                                        </div>
                                    })
                                }
                            </InfiniteScroll>
                        }
                        {
                            !filteredData.length && <span>Nenhum resultado encontrado</span>
                        }
                    </div>
                    {
                        enableMultiSelection && <section className={`footer-section ${areSelectedArraysEqual() ? '' : 'enable-apply'}`}
                            onClick={() => handleApplySelection()}>
                            <span>Aplicar</span>
                        </section>
                    }
                </section>
            </div>
        </StyledFrame>
    </ClickAwayListener>
}

export default forwardRef(PickList);