import { NFCe } from "model/nfce/NFCe";
import { NFCeFilter } from "model/nfce/NFCeFilter";
import api from "./Api";
import format from "date-fns/esm/fp/format/index.js";
import { PageResponse } from '@models/PageResponse';
import fileDownload from 'js-file-download';

export default class NFCeListingApi {

    exportNFCes = async (filters: NFCeFilter) => {
        try {
            const requestData = {
                status: filters.status,
                initialDate: filters.initialDateObj ? format("dd/MM/yyyy", filters.initialDateObj) : null,
                finalDate: filters.finalDateObj ? format("dd/MM/yyyy", filters.finalDateObj) : null,
                minValue: filters.vlrMinNf,
                maxValue: filters.vlrMaxNf,
                docDestinatario: filters.docDestinatario,
                serie: filters.serie,
                numDoc: filters.numDoc,
                accessKeys: filters.chaveNf,
                branchesIds: filters.branchesIds,
                companyId: filters.companyId,
                inutilizada: filters.inutilizada
            }

            const res = await api.get('/nfce/export', { responseType: 'blob', params: requestData });

            fileDownload(res.data, res.headers.filename)

        } catch (err) {
            console.log("Failed to export NFCes. ", err);
            return null;
        }
    };

    downloadXml = async (nfce: NFCe) => {
        try {

            const response = await api.get(`/nfce/${nfce.id}/download`, {
                responseType: 'blob'
            });

            if (response.status === 200) {
                fileDownload(response.data, response.headers.filename)
            }

            return response;
        } catch (err) {
            console.log("Failed to download the NFCe xml file.", err);
            return null;
        }
    }

    downloadXmlBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfce/download/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the NFCe xml batch file.", err);
            return null;
        }
    }

    downloadDanfce = async (nfce: NFCe) => {
        try {
            const response = await api.get(`/nfce/${nfce.id}/danfce`, { responseType: 'blob' });

            return response;
        } catch (err) {
            console.log("Failed to download NFCe. ", err);
            return null;
        }
    }

    downloadDanfceBatch = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfce/danfe/batch`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the NFCe xml batch file.", err);
            return null;
        }
    }

    downloadDanfceMerged = async (ids: number[]) => {
        try {
            const response = await api.get(`/nfce/danfe/merged`, {
                params: { ids: ids.join(',') },
                responseType: 'blob'
            });

            return response;
        } catch (err) {
            console.log("Failed to download the DANFe merged file.", err);
            return null;
        }
    }

    cancelNFCe = async ({ id }: NFCe, justification: string): Promise<any> => {
        try {
            return api.post('/nfce/cancel', { id, justification });
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar cancelar a NFCe.");
        }
    }

    fetchNFCeHistory = async (nfceId: Number): Promise<any> => {
        try {
            return api.get(`/nfce/${nfceId}/history`);
        } catch (err) {
            throw new Error("Ocorreu um erro ao buscar o histórico da NFCe.");
        }
    }

    fetchFilteredNFCes = (filters: NFCeFilter, pageIndex: Number, pageSize: Number, orderBy: string, orderDirection: "ASC" | "DESC"): Promise<PageResponse<NFCe>> => {
        try {
            const requestData = {
                status: filters.status,
                initialDate: filters.initialDateObj ? format("dd/MM/yyyy", filters.initialDateObj) : null,
                finalDate: filters.finalDateObj ? format("dd/MM/yyyy", filters.finalDateObj) : null,
                minValue: filters.vlrMinNf,
                maxValue: filters.vlrMaxNf,
                docDestinatario: filters.docDestinatario,
                serie: filters.serie,
                numDoc: filters.numDoc,
                accessKeys: filters.chaveNf,
                branchesIds: filters.branchesIds,
                companyId: filters.companyId,
                transactionsIds: filters.ids,
                page: pageIndex,
                pageSize: pageSize,
                sort: `${orderBy},${orderDirection}`,
                inutilizada: filters.inutilizada
            }

            return api.get('/nfce', { params: requestData }).then(({ data }) => data);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar buscar a listagem de NFCe.");
        }
    };

    fetchConfig = async (rootId: number | undefined, branchId: number): Promise<any> => {
        const requestData = {
            rootId,
            branchId
        }
        try {
            return api.get('/nfce/config', { params: requestData } );
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar buscar as configurações.");
        }
    }

    canSync = async (rootId: number | undefined): Promise<any> => {
        const requestData = {
            rootId
        }
        try {
            return api.get('/nfe/config/canSync', { params: requestData } );
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar validar o sync.");
        }
    }

    saveConfig = async (payload : any): Promise<any> => {
        try {
            return api.post('/nfce/config', payload);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar salvar as configurações.");
        }
    }
    
    inutilizeBatch = async (ids: number[]): Promise<any> => {
        try {
          return api.post('/nfce/skip-numbers', ids).then(({ data }) => data);
        } catch (err) {
          throw new Error("Ocorreu um erro inutilizar a numeração em massa.");
        }
    }

    fetchNFCeInfo = async (transactionId?: string): Promise<any> => {
        try {
            const requestData = {
                transactionId
            }
            return api.get(`/nfce/nfce-info`, { params: requestData });
        } catch (err) {
            throw new Error("Ocorreu um erro ao buscar a NFCe.");
        }
    }

    reissueNFCe = async (id: number | undefined, payload: any): Promise<any> => {
        try {
            return api.put(`/nfce/${id}/reissue`, payload);
        } catch (err) {
            throw new Error("Ocorreu um erro ao tentar enviar a NFCe para reemissão.");
        }
    }

}