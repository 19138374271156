import { NFCe } from "@models/nfce/NFCe";
import { NFe } from "@models/NFe";

interface NfeTabProps {
    activeNFe: NFe | NFCe
}

export function TotalsTab({ activeNFe }: NfeTabProps) {

    const total = activeNFe?.infNfe?.total
    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Base de Cálculo ICMS</span>
                        <input type="number" value={total?.icmstot?.vbc} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor do ICMS</span>
                        <input type="number" value={total?.icmstot?.vicms} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor do ICMS Desonerado</span>
                        <input type="number" value={total?.icmstot?.vicmsdeson} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <span className="input-label">Valor Total do FECP</span>
                        <input type="number" value={total?.icmstot?.vfcp} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor Total ICMS FECP</span>
                        <input type="number" value={total?.icmstot?.vfcp} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor ICMS Interestadual UF Destino</span>
                        <input type="number" value={total?.icmstot?.vicmsufdest} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor ICMS Interestadual UF Rem.</span>
                        <input type="number" value={total?.icmstot?.vicmsufremet} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <span className="input-label">Base de Cáculo ICMS ST</span>
                        <input type="number" value={total?.icmstot?.vbcst} disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', marginRight: '15px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '50%' }}>
                            <span className="input-label">Valor ICMS Substituição</span>
                            <input type="number" value={total?.icmstot?.vst} disabled />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <span className="input-label">Valor FCP retido por ST</span>
                            <input type="number" value={total?.icmstot?.vfcpstret} disabled />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <span className="input-label">Valor FCP retido anteriormente por ST</span>
                        <input type="number" value={total?.icmstot?.vfcpstret} disabled />
                    </div>

                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '50%' }}>
                        <span className="input-label">Valor total da quantidade tributada do ICMS monofásico próprio</span>
                        <input type="number" disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <span className="input-label">Valor total do ICMS monofásico próprio</span>
                        <input type="number" disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '50%' }}>
                        <span className="input-label">Valor total da quantidade tributada do ICMS monofásico sujeito a retenção</span>
                        <input type="number" disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <span className="input-label">Valor total do ICMS monofásico sujeito a retenção</span>
                        <input type="number" disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '50%' }}>
                        <span className="input-label">Valor total da quantidade tributada do ICMS monofásico retido anteriormente</span>
                        <input type="number" disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <span className="input-label">Valor total do ICMS monofásico retido anteriormente</span>
                        <input type="number" disabled />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor Total dos Produtos</span>
                        <input type="number" value={total?.icmstot?.vprod} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor do Frete</span>
                        <input type="number" value={total?.icmstot?.vfrete} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor do Seguro</span>
                        <input type="number" value={total?.icmstot?.vseg} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <span className="input-label">Valor Total dos Descontos</span>
                        <input type="number" value={total?.icmstot?.vdesc} disabled />
                    </div>

                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                        <span className="input-label">Valor Total do II</span>
                        <input type="number" value={total?.icmstot?.vii} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor total do IPI</span>
                        <input type="number" value={total?.icmstot?.vipi} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor total do IPI Devolvido</span>
                        <input type="number" value={total?.icmstot?.vipidevol} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <span className="input-label">Valor do PIS</span>
                        <input type="number" value={total?.icmstot?.vpis} disabled />
                    </div>

                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px', }}>
                        <span className="input-label">Valor da COFINS</span>
                        <input type="number" value={total?.icmstot?.vcofins} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Outras Despesas Acessórias</span>
                        <input type="number" value={total?.icmstot?.voutro} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label">Valor total da NFe</span>
                        <input type="number" value={total?.icmstot?.vnf} disabled />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <span className="input-label">Valor Aproximado dos Tributos</span>
                        <input type="number" value={total?.icmstot?.vtottrib} disabled />
                    </div>
                </div>

            </div>
        </aside>
    </>;
}