import { SimpleTable } from "@components/table/SimpleTable";
import Table, { Column } from "@components/table/table";
import { NFCe } from "@models/nfce/NFCe";
import { MOD_FRETE, NFe } from "@models/NFe";
import { formatToCNPJ } from "brazilian-values";

interface NfeTabProps {
    activeNFe: NFe | NFCe
}

export function FreightTab({ activeNFe }: NfeTabProps) {

    const columns: Column[] = [
        { header: 'Quantidade', accessor: 'qvol', isActive: true, minWidth: 125 },
        { header: 'Espécie', accessor: 'esp', isActive: true, minWidth: 210 },
        { header: 'Marca dos Volumes', accessor: 'marca', isActive: true, minWidth: 130 },
        { header: 'Numeração', accessor: 'nvol', isActive: true, minWidth: 80 },
        { header: 'Peso Líquido', accessor: 'pesoL', isActive: true, minWidth: 80 },
        { header: 'Peso Bruto', accessor: 'pesoB', isActive: true, minWidth: 80 }
    ];

    const transp = activeNFe?.infNfe?.transp
    const transporta = transp?.transporta

    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Modalidade do Frete</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.transp?.modFrete && MOD_FRETE[activeNFe?.infNfe?.transp?.modFrete]} />
                    </div>
                </div>
            </div>
        </aside>
        {transporta && 
            <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                <span className="header">Transportador</span>
                <div className="content">
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ</span>
                            <input type="text" disabled={true} value={transporta?.cnpj && formatToCNPJ(transporta.cnpj)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Razão Social / Nome</span>
                            <input type="text" disabled={true} value={transporta?.xnome} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Estadual</span>
                            <input type="text" disabled={true} value={transporta?.ie} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Endereço Completo</span>
                            <input type="text" disabled={true} value={transporta.xender} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Município</span>
                            <input type="text" disabled={true} value={transporta?.xmun} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                            <input type="text" disabled={true} value={transporta?.uf} />
                        </div>
                    </div>
                </div>
            </aside>
        }
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Volumes</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <SimpleTable columns={columns} data={transp?.vol || []} onClickRow={() => {}}/>
                </div>
            </div>
        </aside>
    </>;
}