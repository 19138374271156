import styled from "styled-components";

const StyledTable = styled.div<{ listLength: number }>`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: calc(100vh - 140px);

    

    position: relative;

    font-size: .85rem;
    color: #000;

    padding-top: 12px;
    padding-bottom: calc(12px + 10px); //12px from padding value plus 10px for tbody translation

    .header-frame {
        width: calc(100% - 12px);
        height: 30px;
        
        padding: 0 12px;

        display: flex;
        justify-content: space-between;
        
        position: relative;

        input {
            margin-right: 8px;
        }
        
        .header-item-frame {
            height: 100%;
            width: 100%;

            display: flex;

            margin-left: 8px;

            :not(:first-of-type) {
                margin-left: 20px;
            }

            ${({ listLength }) => {
        return listLength > 15 ? `
                            :last-child {
                                margin-right: 12px;
                            }` : '';
    }}

            color: var(--color-darkest);

            transition: opacity .2s ease-in;

            :hover {
                cursor: pointer;
                opacity: 1;
            }

            > i {
                margin: auto 0;
                margin-left: 6px;
                margin-right: 4px;
                font-size: .75rem;
                color: var(--color-primary);
            }

            > span {
                margin: auto 0;
                font-weight: bold;
                white-space: nowrap;
            }
        }
        
        .info-columns-frame {
            display: flex;
            width: calc(100% - 202px);

            overflow: hidden;
            margin-right: auto;

            background-color: #ff00006a;
        }

        .group-selection-btn {
            display: flex;
            align-items: center;
            
            margin-left: -6px;
            padding: 0 0 0 6px;
            
            border: 1px solid var(--grey-light);
            border-radius: 6px;

            cursor: pointer;

            background-color: var(--white);

            :active {
                background-color: var(--grey-lightest);
            }
            
            :focus-visible {
                outline: none;
            }

            i {
                margin-right: 8px;
                font-size: .65rem;
            }
        }
    }

    .list-frame {
        height: calc(100% - 12px);
        max-height: calc(100% - 16px);
        width: calc(100% - 2px); // 100% minus the border width

        margin-top: 6px;

        border: 1px solid var(--grey-light);
        border-radius: 8px;

        background-color: var(--white);

        overflow-y: auto;

        position: relative;

        .row-frame {
            min-height: 30px;

            padding: 2px 4px 2px 12px;

            align-items: center;

            :hover {
                background-color: #edffec;
                cursor: pointer;
            }

            .row-item {
                width: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                color: var(--grey-medium);
                
                :not(:first-child) {
                    margin-left: 22px;
                }
                
                :first-child {
                    margin-left: 4px;
                }
            }
            
            input {
                margin-right: 8px;
            }
        }
    }
`;

interface SimpleTableProps {
    data: Array<any>,
    columns: Array<Column>,
    onClickRow: Function
}

export interface Column {
    header: string,
    accessor: string,
    orderAccessor?: string,
    minWidth: number
    formatter?: Function
}

export function SimpleTable({ data, columns, onClickRow }: SimpleTableProps) {

    const defineColValue = (colValue, formatter) => {
        var rawValue = colValue || 'N/A';
        if (typeof colValue === 'number') {
            rawValue = colValue || '0';
        }
        return formatter ? formatter(rawValue) : rawValue ;
    }

    return <StyledTable listLength={data.length}>
        <section className="header-frame">
            {
                columns.map((column, index) => {
                    return <div key={index} className="header-item-frame" style={{ minWidth: column.minWidth }}>
                        <span>{column.header}</span>
                    </div>
                })
            }
        </section>
        <section className="list-frame" style={{ display: 'flex', flexDirection: 'column' }}>
            {
                data.length ? data.map((row, index) => {
                    return <div
                        key={index}
                        className="row-frame"
                        style={{ display: 'flex', backgroundColor: `${index % 2 > 0 ? 'var(--grey-lighter)' : 'white'}` }}
                        onClick={() => onClickRow(index, row)}>
                        {columns.map(col => {
                            return <div key={col.accessor} className="row-item" style={{ minWidth: col.minWidth, width: '100%' }}>{defineColValue(row[col.accessor], col.formatter)}</div>;
                        })}

                    </div>
                }) : <div className="loading">Nenhum resultado encontrado</div>
            }
        </section>
    </StyledTable>
}