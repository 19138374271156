import { SimpleTable } from "@components/table/SimpleTable";
import { Column } from "@components/table/table";
import { NFe } from "@models/NFe";
import { useState } from "react";
import { ItemModal } from "../modal/ItemModal";
import { TNFeInfNFeDet } from "@models/TNFe";
import { NFCe } from "@models/nfce/NFCe";

interface NfeTabProps {
    activeNFe: NFe | NFCe
}

export function ItensTab({ activeNFe }: NfeTabProps) {

    const [selectedItem, setSelectedItem] = useState<TNFeInfNFeDet>()

    const columns: Column[] = [
        { header: 'Numero', accessor: 'number', isActive: true, minWidth: 15 },
        { header: 'Descrição', accessor: 'description', isActive: true, minWidth: 310 },
        { header: 'Quantidade', accessor: 'quantity', isActive: true, minWidth: 130 },
        { header: 'Unidade Comercial', accessor: 'unit', isActive: true, minWidth: 80 },
        { header: 'Valor (R$)', accessor: 'value', isActive: true, minWidth: 80 }
    ];

    const data = activeNFe?.infNfe?.det.map(item => (
        {
            number: item.nitem,
            description: item.prod.xprod,
            quantity: item.prod.qcom,
            unit: item.prod.ucom,
            value: item.prod.vprod
        }
    )) || [];

    const onClickRow = (index, row) => {
        setSelectedItem(activeNFe.infNfe?.det[index]);
    }

    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <div className="content">
                <SimpleTable columns={columns} data={data} onClickRow={onClickRow} />
            </div>
        </aside>
        {selectedItem && <ItemModal det={selectedItem} handleClose={() => setSelectedItem(undefined)} />}
    </>;
}