import { TNFeInfNFePagDetPag } from "@models/TNFe";
import Modal from "components/modal/modal";
import styled from "styled-components";

const BillingModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        z-index: 10000;

        .modal-body {
            padding: 15px;
            overflow: scroll !important;
        }
`;


interface BillingModalProps {
    handleClose: Function
    pagDet: TNFeInfNFePagDetPag
}


export function BillingModal(props: BillingModalProps) {
    return <BillingModalFrame><Modal
        headerTitle="Visualizar Forma de Pagamento"
        closeOnClickAway={true}
        handleClose={() => props.handleClose()}
        loading={false}
        actionButtons={[]}>
        <div className="edit-frame">
            <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                <div className="content">
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do Pagamento</span>
                            <input type="text" disabled={true} value={props?.pagDet?.vpag} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Data do Pagamento</span>
                            <input type="text" disabled={true} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ transacional do pagamento</span>
                            <input type="text" disabled={true} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF onde o pagamento foi processado</span>
                            <input type="text" disabled={true} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do Pagamento</span>
                            <input type="text" disabled={true} value={props?.pagDet.vpag} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Data do Pagamento</span>
                            <input type="text" disabled={true}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Tipo de Integração Pagamento</span>
                            <input type="text" disabled={true} value={props?.pagDet.card?.tpIntegra} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ da Credenciadora</span>
                            <input type="text" disabled={true} value={props?.pagDet?.card?.cnpj} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Bandeira da operadora</span>
                            <input type="text" disabled={true} value={props?.pagDet.card?.tband} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número de autorização</span>
                            <input type="text" disabled={true} value={props?.pagDet.card?.caut} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ do beneficiário de pagamento</span>
                            <input type="text" disabled={true} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Identificador do terminal de pagamento</span>
                            <input type="text" disabled={true} />
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    </Modal></BillingModalFrame>
}