import { FunctionComponent, useState } from "react";
import Modal from "components/modal/modal";
import { Box, Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import { toast } from "react-toastify";
import { NFCe } from "@models/nfce/NFCe";
import { useNfe } from "@contexts/nfe";
import { CustomTabPanel } from "@components/edit-nfe/tabs/CustomTabPanel";
import { IssuerTab } from "@components/edit-nfe/tabs/IssuerTab";
import { RecipientTab } from "@components/edit-nfe/tabs/RecipientTab";
import { ItensTab } from "@components/edit-nfe/tabs/ItensTab";
import { TotalsTab } from "@components/edit-nfe/tabs/TotalsTab";
import { FreightTab } from "@components/edit-nfe/tabs/FreightTab";
import { BillingTab } from "@components/edit-nfe/tabs/BillingTab";
import { AdditionalInfoTab } from "./tabs/AdditionalInfoTab";
import { NfeTab } from "@components/edit-nfe/tabs/NfeTab";
import { nfceStatus } from "@models/nfce/NFCeStatus";
import { NFE_ENVIRONMENT } from "@models/NFe";
import { StyledTab, StyledTabs } from "@components/edit-nfe";

const EditModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        transform: translateY(-96px);
        z-index: 1000;

        .modal-body {
            padding: 15px;
            overflow: scroll;
        }

        .confirm-edit-frame {
        display: flex;
        flex-direction: column;
        text-align: center;

        > span {
            margin-bottom: 12px;
        }
    }
`;

interface EditNfceProps {
    activeNFe: NFCe,
    handleClose: Function,
    handleReissue: Function
}

const EditNfce: FunctionComponent<EditNfceProps> = (props) => {

    const [value, setValue] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [editConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);
    const [cancelEditConfirmDialogOpen, setCancelEditConfirmDialogOpen] = useState(false);

    const { editedNfe, reset, updateEditedNfe } = useNfe();

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const validateNFe = () => {
        if (!editedNfe?.razaoSocial) {
            toast.warn("Razão Social ou nome do destinatário do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.taxId) {
            toast.warn("CNPJ ou CPF do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.endereco) {
            toast.warn("Endereço do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.bairro) {
            toast.warn("Bairro do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.numero) {
            toast.warn("Número do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.codMunicipio) {
            toast.warn("Código do municipio do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.municipio) {
            toast.warn("Municipio do destinatário deve ser informado");
            return;
        }
        if (!editedNfe?.uf) {
            toast.warn("UF do destinatário deve ser informado");
            return;
        }

        setEditConfirmDialogOpen(true);

    }

    function startEditMode() {
        updateEditedNfe({
            troco: activeNfe.infNfe?.pag.vtroco,
            infAdFisco: activeNfe.infNfe?.infAdic?.infAdFisco,
            infCpl: activeNfe.infNfe?.infAdic?.infCpl,
            razaoSocial: activeNfe.infNfe?.dest?.xnome,
            taxId: activeNfe.infNfe?.dest?.cpf,
            endereco: activeNfe.infNfe?.dest?.enderDest?.xlgr,
            numero: activeNfe.infNfe?.dest?.enderDest?.nro,
            bairro: activeNfe.infNfe?.dest?.enderDest?.xbairro,
            cep: activeNfe.infNfe?.dest?.enderDest?.cep,
            codMunicipio: activeNfe.infNfe?.dest?.enderDest?.cmun,
            municipio: activeNfe.infNfe?.dest?.enderDest?.xmun,
            fone: activeNfe.infNfe?.dest?.enderDest?.fone,
            codPais: activeNfe.infNfe?.dest?.enderDest?.cpais,
            pais: activeNfe.infNfe?.dest?.enderDest?.xpais,
            uf: activeNfe.infNfe?.dest?.enderDest?.uf,
            suframa: activeNfe.infNfe?.dest?.isuf,
            ie: activeNfe.infNfe?.dest?.ie,
            ieType: activeNfe.infNfe?.dest?.indIEDest,
            im: activeNfe.infNfe?.dest?.im,
            email: activeNfe.infNfe?.dest?.email
        })
        setEditMode(true);

    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClose = () => {
        if (editMode) {
            reset();
            setEditMode(false)
            setCancelEditConfirmDialogOpen(false)
            return;
        }
        setEditMode(false)
        props.handleClose();
    }

    const handleConfirm = () => {
        props.handleReissue(editedNfe);
        setEditConfirmDialogOpen(false);
    }

    const actions = [{
        text: 'Cancelar',
        actionCallback: () => setCancelEditConfirmDialogOpen(true),
        positiveAction: false
    },
    {
        text: 'Salvar',
        actionCallback: () => { validateNFe() },
        positiveAction: true
    }];

    const disableEditMode = () => {
        const status = activeNfe?.status;
        return editMode || (status !== "ERRO_SEFAZ" && status !== "REJEITADO_SEFAZ" && status !== "ERRO_ENVIO")
    }

    const onCloseModal = () => {
        if (editMode) {
            setCancelEditConfirmDialogOpen(true)
            return;
        }
        handleClose();
    }

    const activeNfe = props?.activeNFe;

    if (!activeNfe) {
        return null
    }


    return <EditModalFrame>
        <Modal
            headerTitle={`${editMode ? "Editar" : "Visualizar"} Documento - ${props.activeNFe.infNfe?.ide?.nnf}-${props.activeNFe.infNfe?.ide?.serie}`}
            closeOnClickAway={false}
            handleClose={() => onCloseModal()}
            loading={false}
            headerActions={disableEditMode() ? [] : [
                {
                    icon: (<i title="Editar" className="bi bi-pencil" style={{ fontSize: '1rem' }} />),
                    actionCallback: () => startEditMode()
                }
            ]}
            actionButtons={editMode ? actions : []}
        >
            <div className="edit-frame">
                <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                    <div className="content">
                        <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Chave de acesso</span>
                                <input type="number" disabled={true} value={activeNfe.accessKey} style={{ minWidth: 330 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número</span>
                                <input type="text" disabled={true} value={activeNfe.docNum} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Status</span>
                                <input type="text" disabled={true} value={nfceStatus.filter(st => st.value?.includes(activeNfe.status || ""))[0]?.label ?? ""} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Ambiente</span>
                                <input type="text" disabled={true} value={activeNfe.infNfe?.ide?.tpAmb && NFE_ENVIRONMENT[activeNfe.infNfe?.ide?.tpAmb]} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Versão XML</span>
                                <input type="text" disabled={true} value={activeNfe.infNfe?.versao} />
                            </div>
                        </div>
                    </div>
                </aside>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs value={value} onChange={handleChange}
                        aria-label="scrollable auto tabs example">
                        <StyledTab label="NFCe" {...a11yProps(0)} />
                        <StyledTab label="Emitente" {...a11yProps(1)} />
                        <StyledTab label="Destinatário" {...a11yProps(2)} />
                        <StyledTab label="Produtos/Serviços" {...a11yProps(3)} />
                        <StyledTab label="Totais" {...a11yProps(4)} />
                        <StyledTab label="Transporte" {...a11yProps(5)} />
                        <StyledTab label="Cobrança" {...a11yProps(6)} />
                        <StyledTab label="Inf. Adicionais" {...a11yProps(7)} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <NfeTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <IssuerTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <RecipientTab activeNFe={activeNfe} editMode={editMode} isNFCe={true} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <ItensTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <TotalsTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <FreightTab activeNFe={activeNfe} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                    <BillingTab activeNFe={activeNfe} editMode={editMode} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={7}>
                    <AdditionalInfoTab activeNFe={activeNfe} editMode={editMode} />
                </CustomTabPanel>

            </div>
        </Modal>

        {!!editConfirmDialogOpen &&
            <Modal
                headerTitle="Confirmar edição do documento"
                closeOnClickAway={false}
                handleClose={() => setEditConfirmDialogOpen(false)}
                loading={false}
                actionButtons={[{
                    text: 'Cancelar',
                    actionCallback: () => setEditConfirmDialogOpen(false),
                    positiveAction: false
                },
                {
                    text: 'Confirmar',
                    actionCallback: () => { handleConfirm() },
                    positiveAction: true
                }]}>
                <div className="confirm-edit-frame">
                    <span>Ao salvar a alteração dos dados da NFCe será reenviada para autorização na SEFAZ.</span>
                    <span>Deseja realmente reenviar a solicitação de autorização da NFCe?</span>
                </div>
            </Modal>
        }
        {!!cancelEditConfirmDialogOpen &&
            <Modal
                headerTitle="Abandonar a edição do documento"
                closeOnClickAway={false}
                handleClose={() => setCancelEditConfirmDialogOpen(false)}
                loading={false}
                actionButtons={[{
                    text: 'Cancelar',
                    actionCallback: () => setCancelEditConfirmDialogOpen(false),
                    positiveAction: false
                },
                {
                    text: 'Abandonar',
                    actionCallback: () => { handleClose() },
                    positiveAction: true
                }]}>
                <div className="confirm-edit-frame">
                    <span>Deseja realmente abandonar a edição?</span>
                    <span>Lembrando que todos os dados alterados serão perdidos.</span>
                </div>
            </Modal>
        }
    </EditModalFrame>
}

export default EditNfce;