import { TNFeInfNFeDet, TNFeInfNFeDetImposto, TNFeInfNFeDetImpostoICMS } from "@models/TNFe";
import { formatToCNPJ } from "brazilian-values";
import Modal from "components/modal/modal";
import styled from "styled-components";

const ItemModalFrame = styled.div`
        top: 6px;
        left: -14px;
        width: 100%;
        position: absolute;
        z-index: 10000;

        .modal-body {
            padding: 15px;
            overflow: scroll;
        }
`;


interface ItemModalProps {
    handleClose: Function
    det: TNFeInfNFeDet
}


function matchValue(obj: any, value: string): boolean {
    for (const key in obj) {
        return key.includes(value)
    }
    return false;
}

function getFirstNonNull(value: any): any {
    for (const key in value) {
        if (value[key] !== null) {
            return value[key]
        }
    }
    return null;
}

export function ItemModal(props: ItemModalProps) {
    return <ItemModalFrame><Modal
        headerTitle={`Visualizar Item ${props?.det?.nitem}`}
        closeOnClickAway={true}
        handleClose={() => props.handleClose()}
        loading={false}
        actionButtons={[]}>
        <div className="edit-frame">
            <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                <div className="content">
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código do Produto</span>
                            <input type="text" disabled={true} value={props?.det?.prod.cprod} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código NCM</span>
                            <input type="text" disabled={true} value={props?.det?.prod.ncm} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código CEST</span>
                            <input type="text" disabled={true} value={props?.det?.prod.cest} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Indicador de Escala Relevante</span>
                            <input type="text" disabled={true} value={props?.det?.prod.indEscala} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ do Fabricante da Mercadoria</span>
                            <input type="text" disabled={true} value={props?.det?.prod.cnpjFab} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código de Benefício Fiscal na UF</span>
                            <input type="text" disabled={true} value={props?.det?.prod.cbenef} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código EX da TIPI</span>
                            <input type="text" disabled={true} value={props?.det?.prod.extipi} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CFOP</span>
                            <input type="text" disabled={true} value={props?.det?.prod.cfop} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Outras Despesas Acessórias</span>
                            <input type="text" disabled={true} value={props?.det?.prod.voutro} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do Desconto</span>
                            <input type="text" disabled={true} value={props?.det?.prod.extipi} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor Total do Frete</span>
                            <input type="text" disabled={true} value={props?.det?.prod.vfrete} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do Seguro</span>
                            <input type="text" disabled={true} value={props?.det?.prod.vseg} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Indicador de Composição do Valor Total da NF-e</span>
                            <input type="text" disabled={true} value={props?.det?.prod.indTot} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código EAN Comercial</span>
                            <input type="text" disabled={true} value={props?.det?.prod.cean} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Unidade Comercial</span>
                            <input type="text" disabled={true} value={props?.det?.prod.ucom} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Quantidade Comercial</span>
                            <input type="text" disabled={true} value={props?.det?.prod.qcom} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código EAN Tributável</span>
                            <input type="text" disabled={true} value={props?.det?.prod.ceantrib} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Unidade Tributável</span>
                            <input type="text" disabled={true} value={props?.det?.prod.utrib} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Quantidade Tributável</span>
                            <input type="text" disabled={true} value={props?.det?.prod.qtrib} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor unitário de comercialização</span>
                            <input type="text" disabled={true} value={props?.det?.prod.vunCom} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor unitário de tributação</span>
                            <input type="text" disabled={true} value={props?.det?.prod.vunTrib} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número da FCI</span>
                            <input type="text" disabled={true} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número do pedido de compra</span>
                            <input type="text" disabled={true} value={props?.det?.prod.nitemPed} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Item do pedido de compra</span>
                            <input type="text" disabled={true} value={props?.det?.prod.nitemPed} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor Aproximado dos Tributos</span>
                            <input type="text" disabled={true} />
                        </div>
                    </div>
                </div>
            </aside>
            {props.det?.imposto.content.map((imposto, index) => {
                if (matchValue(imposto.value, 'icms')) {
                    const icms = getFirstNonNull(imposto.value);
                    return <aside key={index} className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                        <span className="header">ICMS Normal e ST</span>
                        <div className="content">
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Origem da Mercadoria</span>
                                    <input type="text" disabled={true} value={icms?.orig} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Tributação do ICMS</span>
                                    <input type="text" disabled={true} value={icms?.cst} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Modalidade Definição da BC ICMS NORMAL</span>
                                    <input type="text" disabled={true} value={icms?.modBC} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Base de Cálculo do ICMS Normal</span>
                                    <input type="text" disabled={true} value={icms?.vbc} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Alíquota do ICMS Normal</span>
                                    <input type="text" disabled={true} value={icms?.picms} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do ICMS Normal</span>
                                    <input type="text" disabled={true} value={icms?.vicms} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor da Base de Cálculo do FCP</span>
                                    <input type="text" disabled={true} value={icms?.vbcfcp} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Percentual do Fundo de Combate à Pobreza (FCP)</span>
                                    <input type="text" disabled={true} value={icms?.pfcp} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do Fundo de Combate à Pobreza (FCP)</span>
                                    <input type="text" disabled={true} value={icms?.vfcp} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Base de Cálculo do ICMS ST</span>
                                    <input type="text" disabled={true} value={icms?.vbcst} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Alíquota do ICMS ST</span>
                                    <input type="text" disabled={true} value={icms?.picmsst} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do ICMS ST</span>
                                    <input type="text" disabled={true} value={icms?.vicmsst} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor da Base de Cálculo do FCP retido por Substituição Tributária</span>
                                    <input type="text" disabled={true} value={icms?.vbcfcpst} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Percentual do FCP retido por Substituição Tributária</span>
                                    <input type="text" disabled={true} value={icms?.pfcpst} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do FCP retido por Substituição Tributária</span>
                                    <input type="text" disabled={true} value={icms?.vfcpst} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Percentual Redução de BC do ICMS ST</span>
                                    <input type="text" disabled={true} value={icms?.predBCST} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Percentual do MVA do ICMS ST</span>
                                    <input type="text" disabled={true} value={icms?.pmvast} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Modalidade Definição da BC ICMS ST</span>
                                    <input type="text" disabled={true} value={icms?.modBCST} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor do ICMS- ST desonerado</span>
                                    <input type="text" disabled={true} value={icms?.vicmsdeson} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Motivo da desoneração do ICMS- ST</span>
                                    <input type="text" disabled={true} value={icms?.motDesICMSST} />
                                </div>
                            </div>
                        </div>
                    </aside>
                } else if (matchValue(imposto.value, 'pis')) {
                    const pis = getFirstNonNull(imposto.value)
                    return <aside key={index} className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                        <span className="header">PIS</span>
                        <div className="content">
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CST</span>
                                    <input type="text" disabled={true} value={pis?.cst} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Base de Cálculo</span>
                                    <input type="text" disabled={true} value={pis?.vbc} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Aliquota</span>
                                    <input type="text" disabled={true} value={pis?.ppis} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor</span>
                                    <input type="text" disabled={true} value={pis?.vpis} />
                                </div>
                            </div>
                        </div>
                    </aside>
                } else if (matchValue(imposto.value, 'cofins')) {
                    const cofins = getFirstNonNull(imposto.value)
                    return <aside key={index} className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                        <span className="header">COFINS</span>
                        <div className="content">
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CST</span>
                                    <input type="text" disabled={true} value={cofins?.cst} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Base de Cálculo</span>
                                    <input type="text" disabled={true} value={cofins?.vbc} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Aliquota</span>
                                    <input type="text" disabled={true} value={cofins?.pcofins} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor</span>
                                    <input type="text" disabled={true} value={cofins?.vcofins} />
                                </div>
                            </div>
                        </div>
                    </aside>
                } else if (matchValue(imposto.value, 'ipi')) {
                    const ipi = getFirstNonNull(imposto.value)
                    return <aside key={index} className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
                        <span className="header">Imposto Sobre Produtos Industrializados</span>
                        <div className="content">
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Classe de Enquadramento</span>
                                    <input type="text" disabled={true} value={ipi?.vbc} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código de Enquadramento</span>
                                    <input type="text" disabled={true} value={imposto.value.cenq} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Código do Selo</span>
                                    <input type="text" disabled={true} value={imposto.value.cselo} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ do Produtor</span>
                                    <input type="text" disabled={true} value={imposto.value.cnpjprod && formatToCNPJ(imposto.value.cnpjprod)} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Qtd. Selo</span>
                                    <input type="text" disabled={true} value={imposto.value.qselo} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CST</span>
                                    <input type="text" disabled={true} value={ipi?.cst} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Qtd Total Unidade Padrão</span>
                                    <input type="text" disabled={true} value={ipi?.qUnid} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor por Unidade</span>
                                    <input type="text" disabled={true} value={ipi?.vUnid} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor IPI</span>
                                    <input type="text" disabled={true} value={ipi?.vipi} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Base de Cálculo</span>
                                    <input type="text" disabled={true} value={ipi?.vbc} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Alíquota</span>
                                    <input type="text" disabled={true} value={ipi?.pipi} />
                                </div>
                            </div>
                        </div>
                    </aside>
                }
                return <></>;
            })}
            <aside className={['bottom-frame', 'visible'].join(' ')}>
                <span className="header">Informações adicionais do produto</span>
                <div className="content">
                    <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Descrição</span>
                            <input type="text" disabled={true} value={props?.det?.infAdProd} />
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    </Modal></ItemModalFrame>
}