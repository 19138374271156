import { NFCe } from "@models/nfce/NFCe";
import { CONSUMIDOR_FINAL, DEST_OPERACAO, FINALIDADE, INTERMEDIADOR, NFe, PRESENCA_COMPRADOR, PROCESSO, TIPO_EMISSAO, TIPO_OPERACAO } from "@models/NFe";
import { TNFeInfNFe } from "@models/TNFe";
import { formatToCNPJ, formatToCPF } from "brazilian-values";
import { format } from "date-fns/fp";

interface NfeTabProps {
    activeNFe: NFe | NFCe
}

export function NfeTab({ activeNFe }: NfeTabProps) {

    function formatTaxId(infNfe: TNFeInfNFe | undefined): string {
        if (infNfe?.dest?.cnpj) {
            return formatToCNPJ(infNfe.dest.cnpj);
        } else if (infNfe?.dest?.cpf) {
            return formatToCPF(infNfe.dest.cpf);
        } else if (infNfe?.dest?.idEstrangeiro) {
            return infNfe.dest.idEstrangeiro;
        }

        return "";
    }


    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
            <span className="header">Dados da NFe</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '5%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Modelo</span>
                        <input type="number" disabled={true} value={activeNFe.infNfe?.ide.mod} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '10%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Série</span>
                        <input type="number" disabled={true} value={activeNFe?.series ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Número</span>
                        <input type="number" disabled={true} value={activeNFe?.docNum ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Data/Hora Emissão</span>
                        <input type="text" disabled={true} value={activeNFe?.issueDate ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Data/Hora de Saída ou da Entrada</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide?.dhSaiEnt && format("dd/MM/yyyy HH:mm:ss", new Date(activeNFe.infNfe.ide.dhSaiEnt))} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Valor Total da Nota Fiscal </span>
                        <input type="text" disabled={true} value={activeNFe?.nfAmount ?? ""} />
                    </div>
                </div>
            </div>
        </aside>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Emitente</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CNPJ</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.emit.cnpj && formatToCNPJ(activeNFe?.infNfe?.emit.cnpj)} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome/Razão Social</span>
                        <input type="text" style={{ minWidth: 300 }} disabled={true} value={activeNFe?.infNfe?.emit.xnome ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Estadual</span>
                        <input type="number" disabled={true} value={activeNFe?.infNfe?.emit.ie ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '5%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                        <input type="text" disabled={true} value={activeNFe.infNfe?.emit.enderEmit.uf ?? ""} />
                    </div>
                </div>
            </div>
        </aside>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Destinatário</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Documento</span>
                        <input type="text" disabled={true} value={formatTaxId(activeNFe?.infNfe) ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Nome/Razão Social</span>
                        <input type="text" style={{ minWidth: 300 }} disabled={true} value={activeNFe?.infNfe?.dest?.xnome ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Inscrição Estadual</span>
                        <input type="number" disabled={true} value={activeNFe?.infNfe?.dest?.ie ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '5%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>UF</span>
                        <input type="text" disabled={true} value={activeNFe.infNfe?.dest?.enderDest?.uf ?? ""} />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Destino da Operação</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide.idDest && DEST_OPERACAO[activeNFe?.infNfe?.ide.idDest]} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Consumidor Final</span>
                        <input type="text" style={{ minWidth: 300 }} disabled={true} value={activeNFe?.infNfe?.ide.indFinal && CONSUMIDOR_FINAL[activeNFe?.infNfe?.ide.indFinal]} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Presença do Comprador</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide.indPres && PRESENCA_COMPRADOR[activeNFe?.infNfe?.ide.indPres]} />
                    </div>
                </div>
            </div>
        </aside>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Emissão</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '35%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Processo</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide?.procEmi && PROCESSO[activeNFe?.infNfe?.ide?.procEmi]} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Versão do Processo</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide?.verProc ?? ""} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '15%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Tipo Emissão</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide?.tpEmis && TIPO_EMISSAO[activeNFe?.infNfe?.ide?.tpEmis]} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Finalidade</span>
                        <input type="text" disabled={true} value={activeNFe.infNfe?.ide?.finNFe && FINALIDADE[activeNFe.infNfe?.ide?.finNFe]} />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '35%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Natureza Operação</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide.natOp} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '25%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Indicador de Intermediador/Marketplace</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide.indIntermed && INTERMEDIADOR[activeNFe?.infNfe?.ide.indIntermed]} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '15%', marginRight: '15px' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Tipo da Operação</span>
                        <input type="text" disabled={true} value={activeNFe?.infNfe?.ide.tpNF && TIPO_OPERACAO[activeNFe?.infNfe?.ide.tpNF]} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Digest Value da NF-e</span>
                        <input type="text" disabled={true} value={activeNFe.digest ?? ""} />
                    </div>
                </div>
            </div>
        </aside>
    </>;
}