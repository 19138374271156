import { useNfe } from "@contexts/nfe";
import { DANFE, NFe } from "@models/NFe";

interface NfeTabProps {
    activeNFe: NFe
    editMode: boolean
}

export function AdditionalInfoTab({ activeNFe, editMode }: NfeTabProps) {

    const {editedNfe, updateEditedNfe} = useNfe();

    const onChange = (field: string, value: string) => {
        updateEditedNfe({...editedNfe, [field]: value})
    }

    return <>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')}>
            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Formato de Impressão DANFE</span>
                        <input type="text" disabled value={activeNFe.infNfe?.ide?.tpImp && DANFE[activeNFe.infNfe?.ide?.tpImp]} />
                    </div>
                </div>
            </div>
        </aside>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Informações Adicionais de Interesse do Fisco</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Descrição</span>
                        <textarea disabled={!editMode} value={editedNfe?.infAdFisco ?? activeNFe?.infNfe?.infAdic?.infAdFisco ?? ""} style={{height: '50px'}} onChange={e => onChange("infAdFisco", e.target.value)} />
                    </div>
                </div>
            </div>
        </aside>
        <aside className={['bottom-frame', 'edit-border', 'visible'].join(' ')} style={{ marginTop: 10 }}>
            <span className="header">Informações Complementares de Interesse do Contribuinte</span>

            <div className="content">
                <div style={{ display: 'flex', width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px', width: '100%' }}>
                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Descrição</span>
                        <textarea disabled={!editMode} value={editedNfe?.infCpl ?? activeNFe?.infNfe?.infAdic?.infCpl ?? ""} style={{height: '50px'}} onChange={e => onChange("infCpl", e.target.value)}/>
                    </div>
                </div>
            </div>
        </aside>
    </>;
}